import Graph from "../../Graph";
import { useAppSelector } from "../../../../app/hooks";
import { useDispatch } from "react-redux";
import AttemptedTests from "../../AttemptedTests";
import { useEffect } from "react";
import { setActiveTab } from "../../../../app/Slices/studentSlice";
import {
  setLastPageSpeaking,
  setSpeakingCurrentPage,
  setSpeakingResponseData,
  setTestType,
} from "../../../../app/Slices/speakingResultsSlice";
import { useSpeakingAiFeedBackResultsMutation } from "../../../../api/speakingResultsApi";

const Speaking = () => {
  const {
    speakingGraphData,
    speakingResponseData,
    currentPage,
    lastPageSpeaking,
  } = useAppSelector((state) => state.root.speakingResults);
  const [speakingAiFeedBackResults] = useSpeakingAiFeedBackResultsMutation();

  const dispatch = useDispatch();
  const series = [
    {
      type: "line",
      name: "Tests Graded",
      data: speakingGraphData?.scores,
      color: "#FFECA9",
      marker: {
        fillColor: "#FFF",
        lineColor: "#DB991D",
      },
    },
  ];

  const categories = speakingGraphData?.dates;
  useEffect(() => {
    dispatch(setActiveTab("speaking"));
  }, [dispatch]);

  const tabs = [
    { id: "all", value: "All" },
    { id: "full", value: "Full Test" },
    { id: "part1", value: "Part 1" },
    { id: "part2", value: "Part 2" },
    { id: "part3", value: "Part 3" },
  ];

  return (
    <>
      {speakingResponseData ? (
        <>
          <div className="w-full">
            <div className="col-span-2 border border-[#E3E5E9] rounded-lg">
              <Graph
                legendEnabled={false}
                categories={categories}
                title="Speaking Test Score"
                series={series}
                tickInterval={3}
                min={0}
                max={9}
              />
            </div>
          </div>
          <AttemptedTests
            currentPage={currentPage}
            lastPage={lastPageSpeaking}
            data={speakingResponseData}
            tabs={tabs}
            setTestType={setTestType}
            apiCall={speakingAiFeedBackResults}
            setData={setSpeakingResponseData}
            setLastPage={setLastPageSpeaking}
            setCurrentPage={setSpeakingCurrentPage}
          />
        </>
      ) : (
        <div className="flex justify-center text-xl text-black text-center">
          No Data Available
        </div>
      )}
    </>
  );
};

export default Speaking;
