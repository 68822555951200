const SectionWiseQuestions = () => {
  return (
    <div>
      <h1 className="bg-[#F8F9FA] p-4 font-avenir border rounded-md rounded-b-none border-b-gray-300 font-medium text-[#292D35] text-lg">
        Section-wise Questions
      </h1>
      <div className="flex gap-4 px-4 py-2 items-center border-b border-b-gray-300">
        <div className="flex items-center gap-1">
          <div className="w-4 h-4 border-2 border-[#59A51A] rounded"></div>
          <p className="text-[1rem] my-auto font-avenir">Correct</p>
        </div>
        <div className="flex items-center gap-1">
          <div className="w-4 h-4 border-2 border-[#DB3555] rounded"></div>
          <p className="text-[1rem] my-auto font-avenir">Incorrect</p>
        </div>
        <div className="flex items-center gap-1">
          <div className="w-4 h-4 border-2 border-[#737E93] rounded"></div>
          <p className="text-[1rem] my-auto font-avenir">Not attempted</p>
        </div>
      </div>
    </div>
  );
};

export default SectionWiseQuestions;
