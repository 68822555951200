import { useEffect } from "react";
import { useFetchListeningTestChartQuery } from "../../../api/listeningResultsApi";
import { useFetchReadingTestChartQuery } from "../../../api/readingResultsApi";
import { useFetchSpeakingTestChartQuery } from "../../../api/speakingResultsApi";
import { useFetchWritingTestChartQuery } from "../../../api/writingResultsApi";
import { useAppSelector } from "../../../app/hooks";
import Tab from "../../Common/Tab";
import Speaking from "../StudentProfile/Speaking";
import Listening from "./Listening";
import Reading from "./Reading";
import Writing from "./Writing";
import { useDispatch } from "react-redux";
import { setTabs } from "../../../app/Slices/studentSlice";

const StudentProfile = () => {
  const { selectedStudentDetails, dashboards, tab } = useAppSelector(
    (state) => state.root.student
  );
  const dispatch = useDispatch();

  useFetchReadingTestChartQuery(selectedStudentDetails?._id, {
    skip: !selectedStudentDetails?._id || !dashboards.includes(4003),
    refetchOnMountOrArgChange: true,
  });

  useFetchWritingTestChartQuery(selectedStudentDetails?._id, {
    skip: !selectedStudentDetails?._id || !dashboards.includes(4002),
    refetchOnMountOrArgChange: true,
  });

  useFetchSpeakingTestChartQuery(selectedStudentDetails?._id, {
    skip: !selectedStudentDetails?._id || !dashboards.includes(4001),
    refetchOnMountOrArgChange: true,
  });

  useFetchListeningTestChartQuery(selectedStudentDetails?._id, {
    skip: !selectedStudentDetails?._id || !dashboards.includes(4004),
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    const newTabs = [];
    if (dashboards.includes(4001)) {
      newTabs.push({ id: "speaking", value: "Speaking" });
    }
    if (dashboards.includes(4002)) {
      newTabs.push({ id: "writing", value: "Writing" });
    }
    if (dashboards.includes(4003)) {
      newTabs.push({ id: "reading", value: "Reading" });
    }
    if (dashboards.includes(4004)) {
      newTabs.push({ id: "listening", value: "Listening" });
    }
    dispatch(setTabs(newTabs));
  }, [dashboards, selectedStudentDetails]);

  return (
    <div className="px-6">
      <Tab
        classes={"text-grey"}
        activeClass={"text-secondaryColor border-b-[3px] border-secondaryColor"}
        getActiveTab={(activeTabId) => {}}
        tabs={tab}
      >
        <>
          {dashboards.includes(4001) && (
            <Tab.Body id="speaking">
              <div className="mt-5">
                <Speaking />
              </div>
            </Tab.Body>
          )}
          {dashboards.includes(4004) && (
            <Tab.Body id="listening">
              <div className="mt-5">
                <Listening />
              </div>
            </Tab.Body>
          )}
          {dashboards.includes(4002) && (
            <Tab.Body id="writing">
              <div className="mt-5">
                <Writing />
              </div>
            </Tab.Body>
          )}
          {dashboards.includes(4003) && (
            <Tab.Body id="reading">
              <div className="mt-5">
                <Reading />
              </div>
            </Tab.Body>
          )}
        </>
      </Tab>
    </div>
  );
};

export default StudentProfile;
