import { useAppSelector } from "../../../app/hooks";
import AttemptedTestCard from "./AttemptedTestCard";

interface TabContentProps {
  filteredResults: any[];
  key?: string;
}

const TabContent = ({ filteredResults, key }: TabContentProps) => {
  const { activeTab } = useAppSelector((state) => state.root.student);
  return (
    <div className="mt-5">
      <div className="flex w-full justify-between items-center flex-wrap ">
        {filteredResults.length > 0 &&
          filteredResults.map((item) => (
            <div key={item.test_id} className="w-full lg:w-[45%]">
              <AttemptedTestCard
                filteredResults={filteredResults}
                item={item}
                navigateTo={`/${activeTab}testresults/${item?.testId}`}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default TabContent;
