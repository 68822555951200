import { useNavigate, useParams } from "react-router";
import CustomButton from "../components/Common/CustomButton";
import { useVerifyUserMutation } from "../api/signupApi";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsOrg } from "../app/Slices/authSlice";
import Loader from "../components/Common/CustomLoader";

const VerifyUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [verifyUser] = useVerifyUserMutation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (id) {
          const response = await verifyUser(id).unwrap();
          setLoading(false);
          if (response) {
            dispatch(setIsOrg(response.isOrg));
          }
        }
      } catch (error) {
        setLoading(false);
        console.error("Error verifying user:", error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div className="flex justify-center items-center h-screen w-full flex-col">
      {loading && <Loader isLoading={true} />}
      <h1 className="font-bold text-3xl mb-3">Verified !</h1>
      <h2 className="mb-4">You have succesfully verified account</h2>
      <CustomButton
        name="Login"
        onClickHandler={() => {
          navigate("/login");
        }}
        bg="bg-[#A033FF]"
      />
    </div>
  );
};

export default VerifyUser;
