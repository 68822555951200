import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setActiveTabAiFeedback,
  setContent,
} from "../../../../app/Slices/writingResultsSlice";
import Tabs from "../../../Common/WritingResultsTab";
import ShowMoreContent from "./ShowAiFeedbackContent";
import Accordion from "./Accordion";
import { useAppSelector } from "../../../../app/hooks";

function WritingFullTestAIFeedBack() {
  const dispatch = useDispatch();
  const { activeTabAiFeedback, showless, accordian, testType } = useAppSelector(
    (state) => state.root.writingResults
  );
  let { whatWentWrong, improvements, idealAnswer } = accordian;

  useEffect(() => {
    if (testType) {
      if (testType === "part1") {
        dispatch(setActiveTabAiFeedback(0));
      }
      if (testType === "part2") {
        dispatch(setActiveTabAiFeedback(1));
      }
    }
    dispatch(setContent(false));
  }, [dispatch, testType]);

  const { writingFullTestPart1Data, writingFullTestPart2Data } = useAppSelector(
    (state) => state.root.writingResults
  );

  const fetchPartType = () => {
    if (testType === "part1") {
      return [{ label: "Part 1", partType: "part1", content: "" }];
    } else if (testType === "part2") {
      return [{ label: "Part 2", partType: "part2", content: "" }];
    } else if (testType === "part3") {
      return [{ label: "Part 3", partType: "part3", content: "" }];
    } else if (testType === "full") {
      return [
        {
          label: "Part 1",
          partType: "part1",
        },
        { label: "Part 2", partType: "part2" },
      ];
    } else {
      return ["N/A"];
    }
  };

  const getTestType = () => {
    if (testType === "part1") {
      return "part1";
    } else if (testType === "part2") {
      return "part2";
    } else if (testType === "full") {
      return "full";
    } else {
      return "N/A";
    }
  };

  return (
    <div className="border border-[#E3E5E9] bg-[#FFFFFF] w-full ">
      <div className="h-[2.8rem] bg-[#F8F9FA] border-b border-[#E3E5E9] ">
        <Tabs tabs={fetchPartType()} partType={getTestType()} />
      </div>
      <div className="relative">
        <div className=" bg-[#FFFFFF]  px-8 flex flex-col">
          <div>
            <div className="text-[#737E93] font-normal pt-6 ">MY ANSWER</div>
            <div className="py-3 text-[#292D35] border-b border-[#E3E5E9] font-sans text-base text-justify">
              {activeTabAiFeedback === 0 ? (
                <ShowMoreContent
                  content={writingFullTestPart1Data?.userSubmitAnswer}
                  showless={showless}
                />
              ) : null}
              {activeTabAiFeedback !== 0 ? (
                <ShowMoreContent
                  content={writingFullTestPart2Data?.userSubmitAnswer}
                  showless={showless}
                />
              ) : null}
            </div>
            <div>
              <div className="text-[#737E93] font-normal pt-6 pb-3">
                AI FEEDBACK
              </div>
              <div>
                <div className="text-[#E31837] text-lg font-semibold flex">
                  <Accordion
                    label="WHAT WENT WRONG"
                    section="whatWentWrong"
                    bool={whatWentWrong}
                  />
                </div>
                {whatWentWrong && (
                  <div>
                    <div className="py-3 text-[#292D35]  font-sans text-base text-justify">
                      {activeTabAiFeedback === 0
                        ? writingFullTestPart1Data?.aiFeedbackResult
                            ?.WHAT_WENT_WRONG?.overview
                        : writingFullTestPart2Data?.aiFeedbackResult
                            ?.WHAT_WENT_WRONG?.overview}
                    </div>
                    <div className="py-3 border-b border-[#E3E5E9] h-[40%] flex flex-col text-justify">
                      <div className="pb-4">
                        <p className="text-[#E31837] text-md font-sans font-semibold ">
                          1.Task Response
                        </p>
                        :
                        <p className="text-[#292D35] font-sans text-base ">
                          {activeTabAiFeedback === 0
                            ? writingFullTestPart1Data?.aiFeedbackResult
                                ?.WHAT_WENT_WRONG?.["1.Task_Response"]
                            : writingFullTestPart2Data?.aiFeedbackResult
                                ?.WHAT_WENT_WRONG?.["1.Task_Response"]}
                        </p>
                      </div>
                      <div className="pb-4">
                        <span className="text-[#E31837] text-md font-sans font-semibold">
                          2.Coherence and Cohesion:
                        </span>
                        <span className="text-[#292D35] font-sans text-base">
                          {activeTabAiFeedback === 0
                            ? writingFullTestPart1Data?.aiFeedbackResult
                                ?.WHAT_WENT_WRONG?.["2.Coherence_and_Cohesion"]
                            : writingFullTestPart2Data?.aiFeedbackResult
                                ?.WHAT_WENT_WRONG?.["2.Coherence_and_Cohesion"]}
                        </span>
                      </div>
                      <div className="pb-4">
                        <span className="text-[#E31837] text-md font-sans font-semibold">
                          3.Lexical Resource:
                        </span>
                        <span className="text-[#292D35] font-sans text-base">
                          {activeTabAiFeedback === 0
                            ? writingFullTestPart1Data?.aiFeedbackResult
                                ?.WHAT_WENT_WRONG?.["3.Lexical_Resource"]
                            : writingFullTestPart2Data?.aiFeedbackResult
                                ?.WHAT_WENT_WRONG?.["3.Lexical_Resource"]}
                        </span>
                      </div>
                      <div className="pb-4">
                        <span className="text-[#E31837] text-md font-sans font-semibold">
                          4.Grammatical Range and Accuracy:
                        </span>
                        <span className="text-[#292D35] font-sans text-base">
                          {activeTabAiFeedback === 0
                            ? writingFullTestPart1Data?.aiFeedbackResult
                                ?.WHAT_WENT_WRONG?.[
                                "4.Grammatical_Range_and_Accuracy"
                              ]
                            : writingFullTestPart2Data?.aiFeedbackResult
                                ?.WHAT_WENT_WRONG?.[
                                "4.Grammatical_Range_and_Accuracy"
                              ]}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <div className="pt-5">
                  <Accordion
                    label="WHAT IMPROVEMENTS CAN BE DONE"
                    section="improvements"
                    bool={improvements}
                  />
                </div>
                {improvements && (
                  <div>
                    <div className="py-2 font-normal text-[#292D35] font-sans text-base pt-6">
                      {activeTabAiFeedback === 0
                        ? writingFullTestPart1Data?.aiFeedbackResult
                            ?.WHAT_IMPROVEMENTS_CAN_BE_DONE?.heading
                        : writingFullTestPart2Data?.aiFeedbackResult
                            ?.WHAT_IMPROVEMENTS_CAN_BE_DONE?.heading}
                    </div>
                    <div className="py-3 border-b border-[#E3E5E9] text-[#292D35] font-sans text-base">
                      {activeTabAiFeedback === 0
                        ? writingFullTestPart1Data?.aiFeedbackResult?.WHAT_IMPROVEMENTS_CAN_BE_DONE?.points?.map(
                            (item: any) => (
                              <li key={item} className="pb-4">
                                {item}
                              </li>
                            )
                          )
                        : writingFullTestPart2Data?.aiFeedbackResult?.WHAT_IMPROVEMENTS_CAN_BE_DONE?.points?.map(
                            (item: any) => (
                              <li key={item} className="pb-4">
                                {item}
                              </li>
                            )
                          )}
                    </div>
                  </div>
                )}
              </div>
              <div>
                <div className="pt-5">
                  <Accordion
                    label="THE IDEAL ANSWER"
                    section="idealAnswer"
                    bool={idealAnswer}
                  />
                </div>
                {idealAnswer && (
                  <div className="py-3 border-b border-[#E3E5E9] text-[#292D35] font-sans text-base text-justify">
                    {activeTabAiFeedback === 0
                      ? writingFullTestPart1Data?.aiFeedbackResult
                          ?.THE_IDEAL_ANSWER
                      : writingFullTestPart2Data?.aiFeedbackResult
                          ?.THE_IDEAL_ANSWER}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WritingFullTestAIFeedBack;
