import CustomButton from "../../Common/CustomButton";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../app/Slices/modalSlice";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../app/hooks";
import {
  useAssignStudentsMutation,
  useLazyAllGroupingStudentsQuery,
} from "../../../api/institutionTutorApi";
import { setSelectedRows } from "../../../app/Slices/tutorSlice";

const UnassignStudent = () => {
  const { selectedRows } = useAppSelector((state) => state.root.tutor);
  const [allGroupingStudents] = useLazyAllGroupingStudentsQuery();
  const dispatch = useDispatch();
  const [assignStudents] = useAssignStudentsMutation();
  const unAssignBulkStudents = async () => {
    try {
      const res = await assignStudents({
        id: null,
        payload: selectedRows?.studentIds,
      });
      if ("error" in res && res.error && "data" in res.error) {
        toast.error("Unable to unassign student");
      } else {
        dispatch(
          setSelectedRows({
            studentsIds: [],
            tutorId: "",
          })
        );
        allGroupingStudents({});
        dispatch(closeModal());
        toast.success("Students Unassigned Successfully");
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const onClickHandler = async () => {
    await unAssignBulkStudents();
  };
  return (
    <>
      <div className="mt-4 flex justify-center flex-col px-6">
        <p className="text-base text-primaryColor">
          Are you sure you want to unassign the below students?
          <ul
            className="mt-4"
            style={{ listStyleType: "disc", marginLeft: "2rem" }}
          >
            {selectedRows?.studentNames?.length
              ? selectedRows?.studentNames.map((item: any, i: any) => {
                  return <li key={item}>{item}</li>;
                })
              : ""}
          </ul>
        </p>
        <p className="text-base text-grey mt-3 mb-6">
          <span className="font-extrabold">Note: </span>This action will
          un-assign{" "}
          <span>
            {selectedRows && selectedRows.length > 0
              ? selectedRows.map((item: any, i: any) => {
                  return (
                    <span key={item.name} className="font-bold">
                      {item.name} {i !== selectedRows.length - 1 && ","}{" "}
                    </span>
                  );
                })
              : ""}
          </span>{" "}
          and you have to again assign the student to another tutor. If you just
          want to reassign the student, use reassign feature.
        </p>
      </div>
      <div className="mt-4 flex justify-center border-t border-t-[#E3E5E9] py-4">
        <div className="px-6 w-full">
          <CustomButton
            name="UNASSIGN STUDENT(s)"
            onClickHandler={onClickHandler}
            width="w-full"
          />
        </div>
      </div>
    </>
  );
};

export default UnassignStudent;
