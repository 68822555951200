import clock from "../../assets/icons/clock.png";
import student from "../../assets/icons/student.png";
import doubleTick from "../../assets/icons/double_tick.png";
import CustomSearch from "../Common/CustomSearch";
import { useDispatch } from "react-redux";
import { setSelectedTutor } from "../../app/Slices/tutorSlice";
import icon from "../../assets/images/user.svg";
import { useAppSelector } from "../../app/hooks";
import TutorFilters from "../Common/FiltersDropDown/tutorFilters";
import { useEffect, useState } from "react";
import { Tutor } from "../Types";
import { setSearchValue } from "../../app/Slices/filtersSlice";
import {
  useLazyAllTutorsQuery,
  useLazyGet10DayActivityByTutorIdQuery,
} from "../../api/institutionTutorApi";
import useFilters from "../CustomHooks/useFilters";

const TutorsList = () => {
  const { tutorsList, selectedTutorDetails } = useAppSelector(
    (state) => state.root.tutor
  );
  const [allTutors] = useLazyAllTutorsQuery();
  const {
    clearFilters,
    filters,
    handleAscendingSorting,
    handleDescendingSorting,
    onChangeRadioHandler,
  } = useFilters(allTutors);
  const [searchResults, setSearchResults] = useState<Tutor[]>([]);
  const { searchValue } = useAppSelector((state) => state.root.filters);
  const [get10DayActivityByTutorId] = useLazyGet10DayActivityByTutorIdQuery();

  const dispatch = useDispatch();
  const handleTutorClicked = (id: string) => {
    dispatch(setSelectedTutor(id));
    get10DayActivityByTutorId(id);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    dispatch(setSearchValue(value));
  };

  useEffect(() => {
    if (searchValue) {
      const trimmedValue = searchValue.trim().replace(/\s+/g, " ");
      const data = tutorsList?.filter((item: any) =>
        item.name.toLowerCase().includes(trimmedValue.toLowerCase())
      );
      setSearchResults(data);
    }
  }, [searchValue, dispatch, tutorsList]);

  const tutorsListData = () => {
    return searchValue ? searchResults : tutorsList;
  };
  const result = tutorsListData();

  return (
    <div>
      <div className="flex justify-between items-center pr-4 pl-6 relative">
        <CustomSearch
          value={searchValue}
          handleSearchChange={handleSearchChange}
        />
        <TutorFilters
          handleDescendingSorting={handleDescendingSorting}
          handleAscendingSorting={handleAscendingSorting}
          onChangeRadioHandler={onChangeRadioHandler}
          filters={filters}
          clearFilters={clearFilters}
        />
      </div>
      <div className="bg-white mt-5 w-full">
        {result &&
          result.length > 0 &&
          result?.map((item: Tutor) => {
            return (
              <div key={item._id} className="px-4">
                <button
                  onClick={() => item._id && handleTutorClicked(item._id)}
                  className={`flex w-full py-4 cursor-pointer items-center hover:bg-[#FFF1ED] rounded-xl ${
                    selectedTutorDetails._id === item._id
                      ? "bg-[#FFF1ED] rounded-xl"
                      : ""
                  }`}
                >
                  <img
                    className="mx-4 w-20 h-20 rounded-xl"
                    src={item?.profilePhoto ? item.profilePhoto : icon}
                    alt="tutor_image"
                  />
                  <div className="w-[80%] pr-10">
                    <h5 className="text-base text-primaryColor text-left">
                      {item?.name}
                    </h5>
                    <div className="flex justify-between mt-[15px]">
                      <div className="flex">
                        <img
                          style={{ width: 24, height: 24 }}
                          src={student}
                          className="mr-2"
                          alt="icon"
                        />
                        <h5 className="font-extrabold text-base text-[#3C424C]">
                          {item?.noOfStudentsAssigned}
                        </h5>
                      </div>
                      <div className="flex">
                        <img
                          style={{ width: 24, height: 24 }}
                          src={doubleTick}
                          className="mr-2"
                          alt="arrow_icon"
                        />
                        <h5 className="font-extrabold text-base text-[#3C424C]">
                          {item.noOfTestsGraded}
                        </h5>
                      </div>
                      <div className="flex">
                        <img
                          style={{ width: 24, height: 24 }}
                          src={clock}
                          className="mr-2"
                          alt="clock_icon"
                        />
                        <h5 className="font-extrabold text-base text-[#3C424C]">
                          {item.noOfTestsPending}
                        </h5>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            );
          })}
        {result?.length === 0 && (
          <p className="text-center mt-2 p-2">No results found</p>
        )}
      </div>
    </div>
  );
};

export default TutorsList;
