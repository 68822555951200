import { useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import AiFeedbackQuestionDescription from "../AiFeedbackQuestionDescription";
import RenderTable from "../RenderTable";
import useAiFeedBack from "../useAiFeedback";
import SectionWiseQuestions from "../SectionWiseQuestions";
import PartwiseReadingQuestions from "./PartwiseReadingQuestions";
import RenderParagraph from "./RenderParagraph";
import RenderQuestionsName from "./RenderingQuestionsName";
import RenderMatchingHeadingQname from "./RenderMatchingHeadingName";
import FeedbackMessage from "../FeedbackMessage";
import AnswersDisplay from "../AnswerDisplay";

const ReadingAIFeedback = () => {
  const { handleAIFeedback } = useAiFeedBack();
  const [aiFeedback, setAIFeedback] = useState({
    partType: "",
    paragraphTitle: "",
    paragraphContent: "",
    questionId: "",
    questionName: "",
    questionType: "",
    userAnswer: "",
    exactAnswer: "",
    isCorrect: false,
    isSelected: false,
    table: [],
    isAttempted: true,
  });

  const { readingAiFeedback: data } = useAppSelector(
    (state) => state.root.readingResults
  );

  return (
    <div className="w-full mx-auto ">
      <div className="mt-5">
        <div className="flex gap-4">
          <div className="border border-gray-300 rounded-md bg-white w-1/3 min-h-[70vh]">
            <SectionWiseQuestions />
            {data["part1"] && (
              <PartwiseReadingQuestions
                setAIFeedback={setAIFeedback}
                aiFeedback={aiFeedback}
                handleAIFeedback={handleAIFeedback}
                part="Part 1"
                partData={data?.part1}
              />
            )}

            {data["part2"] && (
              <PartwiseReadingQuestions
                setAIFeedback={setAIFeedback}
                aiFeedback={aiFeedback}
                handleAIFeedback={handleAIFeedback}
                part="Part 2"
                partData={data?.part2}
              />
            )}

            {data["part3"] && (
              <PartwiseReadingQuestions
                setAIFeedback={setAIFeedback}
                aiFeedback={aiFeedback}
                handleAIFeedback={handleAIFeedback}
                part="Part 3"
                partData={data?.part3}
              />
            )}
          </div>

          <div className="border border-gray-300 rounded-md bg-white w-8/12">
            <h1 className="bg-[#F8F9FA] p-4 font-avenir border rounded-md rounded-b-none border-b-gray-300 font-medium text-[#292D35] text-lg">
              Analysis
            </h1>
            {!aiFeedback.questionId ? (
              <div className="flex justify-center items-center h-full font-avenir ">
                {" "}
                Please Select the Question to get the feedback
              </div>
            ) : (
              <div className="flex justify-between w-full p-4 text-xs">
                <div className="w-1/2 pr-5">
                  <h1 className="text-sm text-[#737E93] uppercase mb-2 font-semibold font-avenir">
                    Academic Reading {aiFeedback.partType}
                  </h1>
                  <AiFeedbackQuestionDescription
                    questionType={aiFeedback.questionType}
                  />
                  {aiFeedback.questionType !== "Table Completion" ? (
                    <div>
                      <p className="text-[#292D35] text-sm mb-2">
                        {aiFeedback.questionType === "Matching Headings" ? (
                          <RenderMatchingHeadingQname
                            questionName={aiFeedback.questionName}
                            userAnswer={aiFeedback.userAnswer}
                            isCorrect={aiFeedback.isCorrect}
                          />
                        ) : (
                          <RenderQuestionsName
                            questionName={aiFeedback.questionName}
                            dataQuestions={
                              data[`${aiFeedback.partType}`]?.questions
                            }
                          />
                        )}
                      </p>
                      <AnswersDisplay
                        userAnswer={aiFeedback.userAnswer}
                        exactAnswer={aiFeedback.exactAnswer}
                        isCorrect={aiFeedback.isCorrect}
                        isAttempted={aiFeedback.isAttempted}
                        module={"reading"}
                      />
                      <FeedbackMessage
                        isCorrect={aiFeedback.isCorrect}
                        isAttempted={aiFeedback.isAttempted}
                      />
                    </div>
                  ) : (
                    <div>
                      {aiFeedback.table && (
                        <RenderTable aiFeedback={aiFeedback} data={data} />
                      )}
                      <AnswersDisplay
                        userAnswer={aiFeedback.userAnswer}
                        exactAnswer={aiFeedback.exactAnswer}
                        isCorrect={aiFeedback.isCorrect}
                        isAttempted={aiFeedback.isAttempted}
                        module={"reading"}
                      />
                      <FeedbackMessage
                        isCorrect={aiFeedback.isCorrect}
                        isAttempted={aiFeedback.isAttempted}
                      />
                    </div>
                  )}
                </div>
                <div className="w-1/2">
                  <p className="text-sm text-[#4E5664] font-extrabold mb-4 font-avenir">
                    {aiFeedback.paragraphTitle}
                  </p>
                  <p className="text-sm text-justify leading-7">
                    <RenderParagraph paragraph={aiFeedback.paragraphContent} />
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadingAIFeedback;
