import "react-circular-progressbar/dist/styles.css";
import Tab from "../components/Common/Tab";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/Store";
import WritingFullTestAIFeedBack from "../components/Students/AIFeedBack/Writing/writingFullTestAIFeedBack";
import DetailedFullTestResultsWriting from "../components/Students/AIFeedBack/Writing/fullTestResults";
import { useParams } from "react-router-dom";
import { getItem } from "../utils/storage";
import { useWritingAiFeedBackResultsMutation } from "../api/writingResultsApi";
import { useEffect } from "react";
import {
  setTestTypeWriting,
  setWritingResultData,
} from "../app/Slices/writingResultsSlice";
import { setSelectedStudentMobileNumber } from "../app/Slices/studentSlice";
import { setTestId } from "../app/Slices/tutorSlice";
import {
  getWritingFulltestQuestionAnalysis,
  fetchWritingFullTestResults,
} from "../apiUtils";
import ConditionalRenderer from "../components/Students/AIFeedBack/ConditionalResultsRender";
import SubmissionInfo from "../components/Students/AIFeedBack/SubmissionInfoHeader";
import { useAppSelector } from "../app/hooks";

const WritingTestResultsMain = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { writingResultData, writingError } = useSelector(
    (state: RootState) => state.root.writingResults
  );
  const { dashboards, loaderStudent } = useSelector(
    (state: RootState) => state.root.student
  );
  const mobile = getItem("mobileNo");
  const test = getItem("testType");
  const [writingAiFeedBackResults, { data: writingJson }] =
    useWritingAiFeedBackResultsMutation();
  const mobileNumbers = getItem("mobileNumbers");
  const userId = getItem("userId");
  const { isLoading } = useAppSelector((state) => state.root.loader);

  useEffect(() => {
    if (userId && id) {
      if (test === "full" && id && userId) {
        getWritingFulltestQuestionAnalysis(id, "part1", userId, dispatch);
        getWritingFulltestQuestionAnalysis(id, "part2", userId, dispatch);
      }
      if (id && test === "part1") {
        getWritingFulltestQuestionAnalysis(id, "part1", userId, dispatch);
      }
      if (id && test === "part2") {
        getWritingFulltestQuestionAnalysis(id, "part2", userId, dispatch);
      }
      fetchWritingFullTestResults(id, userId, dispatch);
    }
  }, [test, userId, id]);

  useEffect(() => {
    if (Object.keys(writingResultData).length === 0) {
      if (mobileNumbers && test !== "full") {
        writingAiFeedBackResults({
          payload: JSON.parse(mobileNumbers),
          page: 1,
        });
      }
      if (mobile && test !== "full") {
        writingAiFeedBackResults({ payload: [mobile], page: 1 });
      }
    }
  }, []);

  useEffect(() => {
    if (writingJson?.data) {
      dispatch(setWritingResultData(writingJson?.data));
    }
  }, [writingJson]);

  useEffect(() => {
    if (id) {
      dispatch(setTestId(id));
    }
    if (mobile) {
      dispatch(setSelectedStudentMobileNumber(mobile));
    }
    if (test) {
      dispatch(setTestTypeWriting(test));
    }
  }, [id, mobile, test]);

  return (
    <ConditionalRenderer
      isLoading={isLoading}
      loaderStudent={loaderStudent}
      dashboards={dashboards}
      isError={writingError}
    >
      <SubmissionInfo />

      <div className="px-10 ">
        <Tab
          classes={""}
          activeClass={"text-[red] border-b-[3px] border-secondaryColor"}
          getActiveTab={(activeTabId) => {}}
          tabs={[
            { id: "result", value: "Result" },
            { id: "aiFeedback", value: "AI Feedback" },
          ]}
        >
          <Tab.Body id={"result"}>
            <div className="mt-5">
              <DetailedFullTestResultsWriting />
            </div>
          </Tab.Body>
          <Tab.Body id="aiFeedback">
            <div className="flex justify-between items-center">
              <WritingFullTestAIFeedBack />
            </div>
          </Tab.Body>
        </Tab>
      </div>
    </ConditionalRenderer>
  );
};

export default WritingTestResultsMain;
