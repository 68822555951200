import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  setReassignClick,
  setSelectedStudent,
  setSelectedStudentDetails,
} from "../../app/Slices/studentSlice";
import {
  useAllTutorsQuery,
  useAssignStudentsMutation,
  useLazyAllGroupingStudentsQuery,
  useLazyAllTutorsQuery,
  useLazyGetStudentsByTutorIdQuery,
  useAssignedAndUnassignedStudentsQuery,
} from "../../api/institutionTutorApi";
import CustomSideOverlay from "../Common/CustomSideOverlay";
import CustomButton from "../Common/CustomButton";
import StudentProfile from "./StudentProfile";
import CustomSearch from "../Common/CustomSearch";
import noStudentSelectedImg from "../../assets/images/tutor_not_selected.png";
import icon from "../../assets/images/user.svg";
import CircularProgressBar from "../Common/CircularProgressBar";
import { getItem } from "../../utils/storage";
import { useAppSelector } from "../../app/hooks";
import TutorFilters from "../Common/FiltersDropDown/tutorFilters";
import { Tutor } from "../Types";
import { setSearchValueStudent } from "../../app/Slices/filtersSlice";
import Table from "../Common/CustomTable";
import { setSelectedRows } from "../../app/Slices/tutorSlice";
import { setSpeakingCurrentPage } from "../../app/Slices/speakingResultsSlice";
import { setReadingCurrentPage } from "../../app/Slices/readingResultsSlice";
import { setListeningCurrentPage } from "../../app/Slices/listeningResultsSlice";
import { setWritingCurrentPage } from "../../app/Slices/writingResultsSlice";
import { capitalizeFirstLetter, tutorColumn } from "../../utils/utils";
import useFilters from "../CustomHooks/useFilters";

const StudentDetails = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  useAssignedAndUnassignedStudentsQuery(false, {
    refetchOnMountOrArgChange: true,
  });
  const [allTutors] = useLazyAllTutorsQuery();
  const {
    clearFilters,
    filters,
    handleAscendingSorting,
    handleDescendingSorting,
    onChangeRadioHandler,
  } = useFilters(allTutors);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    selectedStudentDetails,
    selectedStudentId,
    totalOverAllBandScore,
    studentId,
  } = useAppSelector((state) => state.root.student);
  const { isOrg } = useAppSelector((state) => state.root.auth);
  const roles = getItem("roles");
  const [searchResults, setSearchResults] = useState<Tutor[]>([]);
  useAllTutorsQuery({});
  const { searchValueStudent } = useAppSelector((state) => state.root.filters);
  const { tutorsList, selectedRows } = useAppSelector(
    (state) => state.root.tutor
  );

  const dispatch = useDispatch();
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [tutorName, setTutorName] = useState<string>("");
  const [assignStudents] = useAssignStudentsMutation();
  const [getStudentsByTutorId] = useLazyGetStudentsByTutorIdQuery();
  const [allGroupingStudents] = useLazyAllGroupingStudentsQuery();

  useEffect(() => {
    if (tutorsList?.length > 0) {
      const tutor = tutorsList.find(
        (item: any) => item._id === selectedStudentDetails?.tutorId
      );
      setTutorName(tutor?.name ?? "");
    }
  }, [tutorsList, selectedStudentDetails]);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    dispatch(setSearchValueStudent(value));
  };

  useEffect(() => {
    if (searchValueStudent) {
      const trimmedValue = searchValueStudent.trim().replace(/\s+/g, " ");
      const data = tutorsList?.filter((item: any) =>
        item.name.toLowerCase().includes(trimmedValue.toLowerCase())
      );
      setSearchResults(data);
    }
  }, [searchValueStudent, dispatch, tutorsList]);

  const tutorsListData = () => {
    return searchValueStudent ? searchResults : tutorsList;
  };
  const result = tutorsListData();
  const assignStudentToTutor = async () => {
    try {
      await assignStudents({
        id: selectedRows.tutorId,
        payload: [studentId],
      });
      getStudentsByTutorId(selectedRows.tutorId);
      dispatch(
        setSelectedStudentDetails({
          ...selectedStudentDetails,
          tutorId: selectedRows.tutorId,
        })
      );
      if (getItem("tabId") === "unassigned") {
        allGroupingStudents({});
        dispatch(setSelectedStudentDetails({}));
        dispatch(setSelectedStudent(""));
      }
      toggleSidebar();
      toast.success("Students Reassigned Successfully");
    } catch (error) {
      toast.error("Unable to reassign student");
    }
  };

  const getSelectedData = (data: any) => {
    const tutorId = data.map((item: any) => item._id);
    dispatch(
      setSelectedRows({
        studentIds: selectedRows.studentIds,
        tutorId: tutorId[0],
      })
    );
  };

  const getTutorsData = useMemo(() => {
    return result?.filter(
      (item: any) => item._id !== selectedStudentDetails.tutorId
    );
  }, [result, selectedStudentDetails?.tutorId]);

  const disableCheckBoxes = () => {
    if (!selectedRows.tutorId) return [];
    return tutorsList
      .filter((item: any) => item._id !== selectedRows.tutorId)
      .map((each) => each._id);
  };

  useEffect(() => {
    if (selectedStudentId) {
      dispatch(setSpeakingCurrentPage(1));
      dispatch(setReadingCurrentPage(1));
      dispatch(setListeningCurrentPage(1));
      dispatch(setWritingCurrentPage(1));
    }
  }, [selectedStudentId]);

  return (
    <div className="h-full">
      {selectedStudentDetails && selectedStudentId ? (
        <>
          <div className="flex justify-between items-center px-6 py-5 bg-[#FFF1ED]">
            <div className="flex">
              <img
                className="rounded-md"
                style={{ width: "88px", height: "88px" }}
                src={
                  selectedStudentDetails?.profilePhoto
                    ? selectedStudentDetails.profilePhoto
                    : icon
                }
                alt="tutor_image"
              />
              <div className="w-full">
                <div>
                  {isOrg && roles?.includes("ADMIN") ? (
                    <p className="px-4 text-2xl text-primaryColor">
                      {selectedStudentDetails?.name &&
                        capitalizeFirstLetter(selectedStudentDetails.name)}
                    </p>
                  ) : (
                    <p className="px-4 text-2xl text-primaryColor mt-6">
                      {selectedStudentDetails?.name &&
                        capitalizeFirstLetter(selectedStudentDetails.name)}
                    </p>
                  )}
                  {isOrg && roles?.includes("ADMIN") && (
                    <>
                      <p className="px-4 text-sm text-grey">
                        Assigned to{" "}
                        {tutorName ? capitalizeFirstLetter(tutorName) : "N/A"}
                      </p>
                      <CustomButton
                        color="text-secondaryColor"
                        width=""
                        bg="bg-[transparent]"
                        name="Reassign"
                        onClickHandler={() => {
                          toggleSidebar();
                          dispatch(setReassignClick(true));
                          dispatch(setSearchValueStudent(""));
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center flex-col">
              <div className="flex justify-center font-extrabold border text-grey bg-[#fff] border-[#E3E5E9] rounded-xl py-2 px-4 w-1/2 text-center">
                <p className="text-xl">
                  {selectedStudentDetails?.testsCompletedCount ?? "N/A"}
                </p>
              </div>
              <p className="text-center mt-4 text-sm text-grey">
                Tests Completed
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="w-12">
                <CircularProgressBar score={[totalOverAllBandScore]} />
              </div>
              <p className="mt-4 text-center text-sm text-grey">
                Overall Band Score
              </p>
            </div>
          </div>
          <StudentProfile />
        </>
      ) : (
        <div className="flex justify-center flex-col items-center w-full h-full">
          <img src={noStudentSelectedImg} alt="No Tutor Selected" />
          <h4 className="text-base text-[#3C424C]">
            Select a Student to see details
          </h4>
          <h4 className="text-base text-grey">Nothing is selected</h4>
        </div>
      )}
      <CustomSideOverlay
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        title={"Reassign Student(s)"}
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <div className="bg-[#F8F9FA] py-[10px] px-5">
          <p>
            Currently Assigned to:{" "}
            <span className="font-bold">{tutorName}</span>
          </p>
        </div>

        <div className="px-6 flex items-center pt-6">
          <CustomSearch
            value={searchValueStudent}
            handleSearchChange={handleSearchChange}
          />
          <TutorFilters
            handleDescendingSorting={handleDescendingSorting}
            handleAscendingSorting={handleAscendingSorting}
            onChangeRadioHandler={onChangeRadioHandler}
            filters={filters}
            clearFilters={clearFilters}
          />
        </div>

        <div className="overflow-auto px-6 flex-grow">
          <Table
            columns={tutorColumn}
            data={getTutorsData}
            getSelectedData={getSelectedData}
            disableHeaderCheckbox
            disableCheckBoxes={disableCheckBoxes()}
          />
        </div>

        <div className="border-t border-t-[#E3E5E9] px-10 py-2 fixed bottom-0 2xl:w-[35%] xl:w-[40%] lg-[45%] md:w-[50%] sm:[50%] w-[80%] bg-white">
          <CustomButton
            width="w-full"
            name="REASSIGN STUDENT"
            onClickHandler={assignStudentToTutor}
            disabled={getTutorsData?.length === 0 || !selectedRows?.tutorId}
          />
        </div>
      </CustomSideOverlay>
    </div>
  );
};

export default StudentDetails;
