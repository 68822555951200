import { useState } from "react";
import tutorIcon from "../../assets/icons/tutor.png";
import tutorActive from "../../assets/icons/tutor_active.png";
import dashboardIcon from "../../assets/icons/home.png";
import studentIcon from "../../assets/icons/student.png";
import hamburgerIcon from "../../assets/icons/hamburger.png";
import studentActive from "../../assets/icons/student_active.png";
import performanceIcon from "../../assets/icons/performance.png";
import performanceActiveIcon from "../../assets/icons/performnaceActive.svg";
import accountIcon from "../../assets/icons/account.png";
import dashboardActive from "../../assets/icons/home_active.png";
import { getItem, setItem } from "../../utils/storage";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setSearchValue,
  setSortColumnNumber,
  setSortTypeNumber,
} from "../../app/Slices/filtersSlice";
import { useAppSelector } from "../../app/hooks";
import { setAssignStudents } from "../../app/Slices/tutorSlice";
import {
  setSelectedStudent,
  setSelectedStudentDetails,
  setTotalOverAllBandScore,
} from "../../app/Slices/studentSlice";

const SidebarV1 = () => {
  const { isOrg } = useAppSelector((state) => state.root.auth);
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let roles = getItem("roles");
  const redirectToAnotherRoute = (route: any) => {
    dispatch(setSearchValue(""));
    dispatch(setSortColumnNumber(0));
    dispatch(setSortTypeNumber(1));
    dispatch(setAssignStudents(false));
    navigate(route);
    setItem("activeMenu", route);
    dispatch(setTotalOverAllBandScore(""));
    localStorage.removeItem("selectedStudentName");
    localStorage.removeItem("mobileNo");
    dispatch(setSelectedStudentDetails({}));
    dispatch(setSelectedStudent(""));
  };

  const Menus = [
    {
      title: "Dashboard",
      src: dashboardIcon,
      active: dashboardActive,
      redirectTo: "/dashboard",
      isVisible: true,
    },
    {
      title: "Tutors",
      src: tutorIcon,
      active: tutorActive,
      redirectTo: "/tutors",
      isVisible: isOrg && roles === "ADMIN",
    },
    {
      title: "Students",
      src: studentIcon,
      active: studentActive,
      redirectTo: "/students",
      isVisible: true,
    },
    {
      title: "Performance",
      src: performanceIcon,
      active: performanceActiveIcon,
      redirectTo: "/performance",
      isVisible: isOrg && roles === "ADMIN",
    },
  ];

  return (
    <div className="flex border-r border-r-[#E3E5E9]">
      <div
        className={` ${
          open ? "w-72" : "w-14 "
        } bg-white h-screen py-5 pt-8 relative duration-300`}
      >
        <div className={`${open && "gap-x-4"} flex items-center px-4`}>
          <button
            className={`cursor-pointer bg-[#FFEAEA] rounded-full p-1 ${
              !open && "rotate-180"
            }`}
            onClick={() => setOpen(!open)}
          >
            <img src={hamburgerIcon} alt="hamburger_Icon" />
          </button>
          {open && (
            <h1
              className={`text-[red] origin-left font-medium text-xl duration-200 ${
                !open && "scale-0"
              }`}
            >
              IELTS
            </h1>
          )}
        </div>
        <ul className="pt-6">
          {Menus.map(
            (Menu, index) =>
              Menu.isVisible && (
                <button
                  key={Menu.title}
                  className={`flex p-4 cursor-pointer text-gray-300 text-sm items-center ${
                    open ? "gap-x-4" : "gap-x-0"
                  }} ${index === 0 && "bg-light-white"}                
          `}
                  onClick={() => redirectToAnotherRoute(Menu.redirectTo)}
                >
                  <li className="flex items-center mt-2" title={Menu.title}>
                    <img
                      src={
                        location.pathname === Menu.redirectTo ||
                        Menu.redirectTo === getItem("activeMenu")
                          ? Menu.active
                          : Menu.src
                      }
                      alt="redirect"
                    />
                    <button
                      className={`${!open && "hidden"} ${
                        location.pathname === Menu.redirectTo ||
                        Menu.redirectTo === getItem("activeMenu")
                          ? "text-secondaryColor font-medium"
                          : "text-grey"
                      } origin-left text-xl duration-200 ml-1 `}
                    >
                      {Menu.title}
                    </button>
                  </li>
                </button>
              )
          )}
        </ul>
        <div
          title="Account Settings"
          className={`w-full flex p-4 cursor-pointer absolute bottom-0 text-gray-300 text-sm border-t border-t-[#E3E5E9] items-center ${
            open ? "gap-x-4" : "gap-x-0"
          } mt-2} ${location.pathname === "/account" ? "bg-[#fff]" : ""}`}
        >
          <button onClick={() => redirectToAnotherRoute("/account")}>
            <div className="flex items-center gap-2">
              <img src={accountIcon} alt="icon" />
              <div
                className={`${!open && "hidden"} ${
                  location.pathname === "/account"
                    ? "text-secondaryColor font-medium"
                    : "text-grey"
                } origin-left text-xl duration-200 `}
              >
                Account Settings
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
export default SidebarV1;
