import { createSlice } from "@reduxjs/toolkit";

interface Filters {
  searchValue: string;
  sortColumnNumber: number;
  sortTypeNumber: number;
  searchValueStudent:string;
}

const initialState: Filters = {
  searchValue: "",
  searchValueStudent:"",
  sortColumnNumber: 0,
  sortTypeNumber: 1,
};
const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSortTypeNumber: (state, action) => {
      state.sortTypeNumber = action.payload;
    },
    setSortColumnNumber: (state, action) => {
      state.sortColumnNumber = action.payload;
    },
    setSearchValueStudent:(state,action)=>{
      state.searchValueStudent = action.payload
    },
  },
});

export const { setSearchValue,setSortTypeNumber,setSortColumnNumber,setSearchValueStudent } = filtersSlice.actions;

export default filtersSlice.reducer;
