import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ToastType {
  status: "Sucess" | "Fail" | "None";
  content: string;
}

const initialState: ToastType = {
  content: "",
  status: "None",
};
const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setToastData: (state, action: PayloadAction<ToastType>) => {
      state.content = action.payload.content;
      state.status = action.payload.status;
    },
  },
});

export const { setToastData } = toastSlice.actions;

export default toastSlice.reducer;
