import "react-circular-progressbar/dist/styles.css";
import Tab from "../components/Common/Tab";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../app/hooks";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { setTestId } from "../app/Slices/tutorSlice";
import { useSpeakingAiFeedBackResultsMutation } from "../api/speakingResultsApi";
import { getItem } from "../utils/storage";
import {
  setGetQuestionAnalysisPartOne,
  setGetQuestionAnalysisPartThree,
  setGetQuestionAnalysisPartTwo,
  setSpeakingFullTestDetailedReport,
  setSpeakingResponseData,
  setTestType,
} from "../app/Slices/speakingResultsSlice";
import { setSelectedStudentMobileNumber } from "../app/Slices/studentSlice";
import axios from "axios";
import { setLoader } from "../app/Slices/loaderSlice";
import { ielts_url } from "../utils/apiConfig";
import SpeakingTestDetailedReport from "../components/Students/AIFeedBack/Speaking/SpeakingTestDetailedReport";
import SpeakingTestAiFeedback from "../components/Students/AIFeedBack/Speaking/SpeakingTestAiFeedback";
import ConditionalRenderer from "../components/Students/AIFeedBack/ConditionalResultsRender";
import SubmissionInfo from "../components/Students/AIFeedBack/SubmissionInfoHeader";

const SpeakingTestResultsMain = () => {
  const [speakingError, setSpeakingError] = useState(false);
  const testType = getItem("testType");
  const { id } = useParams();
  const dispatch = useDispatch();
  const { speakingResponseData } = useAppSelector(
    (state) => state.root.speakingResults
  );
  const mobile = getItem("mobileNo");
  const test = getItem("testType");
  const mobileNumbers = getItem("mobileNumbers");
  const { dashboards, loaderStudent } = useAppSelector(
    (state) => state.root.student
  );
  const [speakingAiFeedBackResults, { data: speakingJson }] =
    useSpeakingAiFeedBackResultsMutation();
  const { isLoading } = useAppSelector((state) => state.root.loader);

  const userId = getItem("userId");
  const fetchSpeakingFullTestResults = async (testId: string) => {
    try {
      dispatch(setLoader(true));
      const res = await axios.get(
        `${ielts_url}/api/get-test-score-tutor?test_id=${id}&user_id=${userId}`
      );
      if (res.status === 200) {
        setSpeakingError(false);
        dispatch(setLoader(false));
        dispatch(setSpeakingFullTestDetailedReport(res.data));
      }
    } catch (error) {
      setSpeakingError(true);
      dispatch(setLoader(false));
    }
  };

  const getSpeakingFulltestQuestionAnalysis = async (
    id: string,
    testType: string
  ) => {
    try {
      dispatch(setLoader(true));
      const res = await axios.get(
        `${ielts_url}/api/get-question-analysis-tutor?test_id=${id}&user_id=${userId}&type=${testType}`
      );
      if (res.status === 200) {
        dispatch(setLoader(false));
        if (testType === "part1") {
          dispatch(setGetQuestionAnalysisPartOne(res.data));
        } else if (testType === "part2") {
          dispatch(setGetQuestionAnalysisPartTwo(res.data));
        } else if (testType === "part3") {
          dispatch(setGetQuestionAnalysisPartThree(res.data));
        }
      }
    } catch (error) {
      dispatch(setLoader(false));
      console.log(error);
    }
  };
  useEffect(() => {
    if (id) {
      if (testType === "full") {
        getSpeakingFulltestQuestionAnalysis(id, "part1");
        getSpeakingFulltestQuestionAnalysis(id, "part2");
        getSpeakingFulltestQuestionAnalysis(id, "part3");
      }
      if (testType === "part1") {
        getSpeakingFulltestQuestionAnalysis(id, "part1");
      }
      if (testType === "part2") {
        getSpeakingFulltestQuestionAnalysis(id, "part2");
      }
      if (testType === "part3") {
        getSpeakingFulltestQuestionAnalysis(id, "part3");
      }
      fetchSpeakingFullTestResults(id);
    }
  }, [testType, id]);

  useEffect(() => {
    if (Object.keys(speakingResponseData).length === 0) {
      if (mobileNumbers && testType !== "full") {
        speakingAiFeedBackResults({
          payload: JSON.parse(mobileNumbers),
          page: 1,
        });
      }
      if (mobile && testType !== "full") {
        speakingAiFeedBackResults({ payload: [mobile], page: 1 });
      }
    }
  }, []);

  useEffect(() => {
    if (speakingJson?.data) {
      dispatch(setSpeakingResponseData(speakingJson?.data));
    }
  }, [dispatch, speakingJson]);

  useEffect(() => {
    if (id) {
      dispatch(setTestId(id));
    }
    if (mobile) {
      dispatch(setSelectedStudentMobileNumber(mobile));
    }
    if (test) {
      dispatch(setTestType(test));
    }
  }, [dispatch, id, mobile, test]);

  return (
    <ConditionalRenderer
      isLoading={isLoading}
      loaderStudent={loaderStudent}
      dashboards={dashboards}
      isError={speakingError}
    >
      <SubmissionInfo />
      <div className="bg-[#F9FBFF] overflow-y-auto">
        <div className="px-10 ">
          <Tab
            classes={""}
            activeClass={"text-[red] border-b-[3px] border-secondaryColor"}
            getActiveTab={(activeTabId) => {}}
            tabs={[
              { id: "result", value: "Result" },
              { id: "aiFeedback", value: "AI Feedback" },
            ]}
            defaultTab={"result"}
          >
            <Tab.Body id={"result"}>
              <div className="mt-5">
                <SpeakingTestDetailedReport />
              </div>
            </Tab.Body>
            <Tab.Body id="aiFeedback">
              <div className="flex justify-between items-center">
                <SpeakingTestAiFeedback />
              </div>
            </Tab.Body>
          </Tab>
        </div>
      </div>
    </ConditionalRenderer>
  );
};

export default SpeakingTestResultsMain;
