import { combineReducers } from "@reduxjs/toolkit";
import tutorSlice from "../Slices/tutorSlice";
import modalSlice from "../Slices/modalSlice";
import studentSlice from "../Slices/studentSlice";
import authSlice from "../Slices/authSlice";
import listeningResultsSlice from "../Slices/listeningResultsSlice";
import readingResultsSlice from "../Slices/readingResultsSlice";
import writingResultsSlice from "../Slices/writingResultsSlice";
import editorSlice from "../Slices/editorSlice";
import speakingResultsSlice from "../Slices/speakingResultsSlice";
import filtersSlice from "../Slices/filtersSlice";
import toastSlice from "../Slices/toastSlice";
import loader from "../Slices/loaderSlice";
import profileSlice from "../Slices/profileSlice";
import tabSlice from "../Slices/tabSlice";

const rootReducer = combineReducers({
  tutor: tutorSlice,
  modal: modalSlice,
  student: studentSlice,
  auth: authSlice,
  listeningResults: listeningResultsSlice,
  readingResults: readingResultsSlice,
  writingResults: writingResultsSlice,
  speakingResults: speakingResultsSlice,
  editor: editorSlice,
  filters: filtersSlice,
  loader,
  toastData: toastSlice,
  profile: profileSlice,
  tab: tabSlice,
});

export default rootReducer;
