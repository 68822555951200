import noTutorSelectedImg from "../../assets/images/tutor_not_selected.png";
import CustomButton from "../Common/CustomButton";
import download from "../../assets/icons/download.png";
import pencil from "../../assets/icons/pencil.png";
import studentActive from "../../assets/icons/student.png";
import doubleTick from "../../assets/icons/double_tick.png";
import clock from "../../assets/icons/clock.png";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../app/Slices/modalSlice";
import {
  setAssignStudents,
  setSelectedRows,
  setSelectedTutorDetails,
} from "../../app/Slices/tutorSlice";
import { RootState } from "../../app/Store";
import StudentsAndGradedTests from "../Tutor/StudentsAndGradedTests";
import { useEffect, useMemo, useState } from "react";
import CustomSideOverlay from "../Common/CustomSideOverlay";
import CustomSearch from "../Common/CustomSearch";
import { toast } from "react-toastify";
import "react-circular-progressbar/dist/styles.css";
import Graph from "./Graph";
import icon from "../../assets/images/user.svg";
import {
  useAllGroupingStudentsQuery,
  useAssignStudentsMutation,
  useAssignedAndUnassignedStudentsQuery,
  useFetchGradedTestsQuery,
  useGetStudentsByTutorIdQuery,
  useLazyAllGroupingStudentsQuery,
} from "../../api/institutionTutorApi";
import {
  setSearchValue,
  setSearchValueTutor,
} from "../../app/Slices/studentSlice";
import Table from "../Common/CustomTable";
import StudentFilters from "../Common/FiltersDropDown/StudentsFilters";
import CircularProgressBar from "../Common/CircularProgressBar";
import useFilters from "../CustomHooks/useFilters";
import { capitalizeFirstLetter, fetchStudents } from "../../utils/utils";

const verticalLine: React.CSSProperties = {
  width: "1px",
  backgroundColor: "#E3E5E9",
};

const TutorDetails = () => {
  const dispatch = useDispatch();
  const {
    tutorsList,
    selectedTutorId,
    selectedTutorDetails,
    tenDayActivityReport,
    selectedRows,
  } = useSelector((state: RootState) => state.root.tutor);
  const { studentsListTutor, searchValue } = useSelector(
    (state: RootState) => state.root.student
  );
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [maxValue, setMaxValue] = useState<number>(18);
  const [assignStudents] = useAssignStudentsMutation();
  const { refetch, isLoading } = useGetStudentsByTutorIdQuery(selectedTutorId, {
    skip: !selectedTutorId,
    refetchOnMountOrArgChange: true,
  });

  useFetchGradedTestsQuery(selectedTutorId, {
    skip: !selectedTutorId,
    refetchOnMountOrArgChange: true,
  });

  const [allGroupingStudents] = useLazyAllGroupingStudentsQuery({});

  const {
    clearFilters,
    filters,
    handleAscendingSorting,
    handleDescendingSorting,
    onChangeRadioHandler,
  } = useFilters(allGroupingStudents);

  useAllGroupingStudentsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [report, setReport] = useState<any>({});

  useAssignedAndUnassignedStudentsQuery(false);

  const openEditDialog = () => {
    dispatch(openModal("editTutor"));
  };

  const openArchiveDialog = () => {
    dispatch(openModal("archiveTutor"));
  };

  const toggleSidebar = () => {
    dispatch(setAssignStudents(true));
    setIsSideBarOpen(!isSideBarOpen);
    allGroupingStudents({});
    dispatch(setSearchValueTutor(""));
    dispatch(setSearchValue(""));
  };

  useEffect(() => {
    if (Object.keys(tenDayActivityReport).length > 0) {
      const formatDate = (dateString: any) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString("default", { month: "short" });
        return `${day} ${month}`;
      };
      const categories = Object.keys(tenDayActivityReport.testsgraded).map(
        formatDate
      );
      const testsGradedData = Object.values(tenDayActivityReport.testsgraded);
      const testsSubmittedData = Object.values(
        tenDayActivityReport.testssubmitted
      );
      setReport({
        categories: categories,
        series: [
          {
            type: "line",
            name: "Tests Graded",
            data: testsGradedData,
            color: "#FFECA9",
            marker: {
              fillColor: "#FFF",
              lineColor: "#DB991D",
            },
          },
          {
            type: "line",
            name: "Tests Submitted",
            data: testsSubmittedData,
            color: "#FFC2B6",
            marker: {
              fillColor: "#FFF",
              lineColor: "#FF7678",
            },
          },
        ],
      });
    } else {
      setReport({ categories: [], series: [] });
    }
  }, [tenDayActivityReport]);

  const assignBulkStudents = async () => {
    try {
      const res = await assignStudents({
        id: selectedTutorId,
        payload: selectedRows?.studentIds,
      });

      if ("error" in res && res.error && "data" in res.error) {
        toast.error("Unable to assign student");
      } else {
        dispatch(
          setSelectedRows({
            studentsIds: [],
            tutorId: "",
          })
        );
        toggleSidebar();
        toast.success("Students Aassigned Successfully");
      }
      if (selectedTutorId) {
        refetch();
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    const selectedTutorDetails = tutorsList?.find(
      (item: any) => item._id === selectedTutorId
    );
    if (selectedTutorDetails) {
      setSelectedTutorDetails(selectedTutorDetails);
      dispatch(setSelectedTutorDetails(selectedTutorDetails));
    }
  }, [selectedTutorId, tutorsList, dispatch]);

  const bandScore = (row: any) => {
    return row.overAllBandScore ? (
      <div style={{ width: "50px", height: "50px" }}>
        <CircularProgressBar score={[row.overAllBandScore]} />
      </div>
    ) : (
      <div style={{ width: "50px", height: "50px" }}>
        <CircularProgressBar score={[0]} />
      </div>
    );
  };

  const columns: any = [
    {
      field: "name",
      name: "Students",
      selector: (row: any) => row.name,
      sortable: false,
      style: {
        fontSize: "18px",
        color: "#292D35",
      },
    },
    {
      field: "testsAttempted",
      name: "Graded Tests List",
      selector: (row: any) => (row.testsAttempted ? row.testsAttempted : "0"),
      sortable: false,
      style: {
        fontSize: "18px",
        color: "#292D35",
      },
    },
    {
      field: "score",
      name: "Band Score",
      selector: (row: any) => bandScore(row),

      sortable: false,
      style: {
        fontSize: "18px",
        color: "#292D35",
      },
    },
  ];

  const dateString = selectedTutorDetails?.createdDate;
  const date = new Date(dateString ?? "");
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const formattedDate = date.toLocaleString("default", options);

  const getSelectedData = (data: any) => {
    const studentIds = data.map((item: any) => item._id);
    const studentNames = data.map((item: any) => item.name);

    dispatch(setSelectedRows({ studentIds, tutorId: "", studentNames }));
  };
  useEffect(() => {
    if (report?.series?.length > 0) {
      const data = report.series[1]?.data;
      if (data && data.length > 0) {
        const newMaxValue = Math.max(...data);
        setMaxValue(newMaxValue);
      }
    } else {
      setMaxValue(18);
    }
  }, [report]);

  const fetchStudentRows = useMemo(() => {
    return fetchStudents(
      searchValue,
      studentsListTutor?.allstudents,
      selectedTutorId
    );
  }, [searchValue, studentsListTutor?.allstudents, selectedTutorId]);

  return (
    <>
      {selectedTutorDetails && selectedTutorId ? (
        <div>
          <div className="flex bg-[#FFF1ED] px-6 py-5">
            <img
              className="rounded-xl"
              style={{ width: "88px", height: "88px" }}
              src={
                selectedTutorDetails?.profilePhoto
                  ? selectedTutorDetails.profilePhoto
                  : icon
              }
              alt="tutor_image"
            />
            <div className="w-full">
              <div className="flex justify-between">
                <p className="px-4 text-2xl text-primaryColor">
                  {capitalizeFirstLetter(selectedTutorDetails?.name)}
                </p>
                <div className="flex mr-6">
                  <button onClick={openArchiveDialog}>
                    <img
                      style={{ width: 24, height: 24 }}
                      src={download}
                      alt="download_icon"
                      className="mr-5 cursor-pointer"
                      title="Archive Tutor"
                    />
                  </button>
                  <button onClick={openEditDialog}>
                    <img
                      style={{ width: 24, height: 24 }}
                      src={pencil}
                      alt="edit_icon"
                      className="cursor-pointer"
                      title="Edit Tutor"
                    />
                  </button>
                </div>
              </div>
              <p className="px-4 text-sm text-grey">
                Tutor Since {formattedDate ?? ""}
              </p>
              <CustomButton
                color="text-secondaryColor"
                width=""
                bg="bg-[transparent]"
                name="Assign New Students"
                onClickHandler={toggleSidebar}
              />
            </div>
          </div>

          <div className="border overflow-x-hidden border-[#E3E5E9] justify-between rounded-[10px] bg-[#FFF] flex w-11/12 mx-auto py-6 px-7 my-5">
            <div className="flex flex-col items-center pr-4 relative">
              <div className="flex">
                <img
                  style={{ width: 24, height: 24 }}
                  src={studentActive}
                  className="mr-1"
                  alt="Student Active"
                />
                <p className="uppercase text-[#4E5664] text-sm mb-[6px]">
                  Students Assigned
                </p>
              </div>
              <p className="text-2xl font-extrabold">
                {selectedTutorDetails?.noOfStudentsAssigned}
              </p>
            </div>

            <div style={verticalLine}></div>

            <div className="flex flex-col items-center px-4 relative">
              <div className="flex">
                <img
                  style={{ width: 24, height: 24 }}
                  src={doubleTick}
                  className="mr-1"
                  alt="Double Tick"
                />
                <p className="uppercase text-[#4E5664] text-sm mb-[6px]">
                  Tests Graded
                </p>
              </div>
              <p className="text-2xl font-extrabold">
                {selectedTutorDetails.noOfTestsGraded}
              </p>
            </div>

            <div style={verticalLine}></div>

            <div className="flex flex-col items-center">
              <div className="flex">
                <img
                  style={{ width: 24, height: 24 }}
                  src={clock}
                  className="mr-1"
                  alt="Clock"
                />
                <p className="uppercase text-[#4E5664] text-sm mb-[6px]">
                  Tests Pending
                </p>
              </div>
              <p className="text-2xl font-extrabold">
                {selectedTutorDetails.noOfTestsPending}
              </p>
            </div>
          </div>

          <div className="w-11/12 mx-auto border bg-white py-5 px-4 border-[#E3E5E9] rounded-[10px]">
            <Graph
              legendEnabled={true}
              categories={report?.categories}
              series={report?.series}
              title="10-Day Activity"
              tickInterval={6}
              min={0}
              max={maxValue >= 18 ? maxValue : 18}
            />
          </div>
          {!isLoading && <StudentsAndGradedTests />}

          <CustomSideOverlay
            isSidebarOpen={isSideBarOpen}
            toggleSidebar={toggleSidebar}
            title={`Assign Student(s) to ${selectedTutorDetails.name}`}
          >
            <div className="bg-[#F8F9FA] py-[10px] px-5 flex justify-between text-sm">
              <p>
                Currently Assigned:{" "}
                <span className="font-bold ml-1">
                  {selectedTutorDetails
                    ? selectedTutorDetails?.noOfStudentsAssigned
                    : 0}
                </span>
              </p>
              <p>
                Selected to Assign:{" "}
                <span className="font-bold ml-1">
                  {selectedRows?.studentIds?.length}
                </span>
              </p>
              <p>
                New Total:{" "}
                <span className="font-bold ml-1">
                  {selectedTutorDetails?.students ??
                    selectedTutorDetails?.noOfStudentsAssigned +
                      selectedRows?.studentIds?.length}
                </span>
              </p>
            </div>

            <div className="px-6 flex items-center pt-6">
              <CustomSearch
                value={searchValue}
                handleSearchChange={(e) => {
                  dispatch(setSearchValue(e.target.value));
                }}
              />
              <StudentFilters
                handleDescendingSorting={handleDescendingSorting}
                handleAscendingSorting={handleAscendingSorting}
                onChangeRadioHandler={onChangeRadioHandler}
                filters={filters}
                clearFilters={clearFilters}
              />
            </div>

            <div className="px-6 pb-20">
              <Table
                columns={columns}
                data={fetchStudentRows}
                getSelectedData={getSelectedData}
              />
            </div>
            <div className="border-t border-t-[#E3E5E9] 2xl:w-[35%] xl:w-[40%] lg-[45%] md:w-[50%] sm:[50%] w-[80%] fixed bottom-0 flex flex-col bg-white">
              <div className="px-10 py-4">
                <CustomButton
                  disabled={selectedRows?.studentIds?.length === 0}
                  width="w-full"
                  name={`ASSIGN ${selectedRows?.studentIds?.length} STUDENT(s)`}
                  onClickHandler={async () => {
                    await assignBulkStudents();
                  }}
                />
              </div>
            </div>
          </CustomSideOverlay>
        </div>
      ) : (
        <div className="flex justify-center flex-col items-center w-full h-full">
          <img src={noTutorSelectedImg} alt="No Tutor Selected" />
          <h4 className="text-base text-[#3C424C]">
            Select a Tutor to see details
          </h4>
          <h4 className="text-base text-grey">Nothing is selected</h4>
        </div>
      )}
    </>
  );
};

export default TutorDetails;
