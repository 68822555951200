import React from 'react';

interface LoaderProps {
  isLoading: boolean;
}

const Loader: React.FC<LoaderProps> = ({ isLoading }) => {
  return isLoading ? (
    <div className="fixed z-[99999] top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="animate-spin rounded-full h-20 w-20 border-t-4 border-red-500"></div>
    </div>
  ) : null;
};

export default Loader;
