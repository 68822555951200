import { LinerProgressBar } from "../LinearProgressBar";

const BandDescription = ({
  title,
  skillLevel,
  overallScore,
  description,
}: any) => (
  <div className="border-2 rounded-[8px] bg-white">
    <div className="flex flex-col h-full">
      <div className="bg-[#F8F9FA] border-b border-b-[#E3E5E9] px-2 py-[18px] text-xl font-semibold font-Avenir text-[#4E5664]">
        {title}
      </div>
      <div className="mx-4 my-5">
        <LinerProgressBar
          score={overallScore}
          key={skillLevel}
          parameter={skillLevel}
        />
      </div>
      <div className="mx-4 mb-3 text-justify text-[#4E5664] font-Avenir">
        <p>{description}</p>
      </div>
    </div>
  </div>
);

export default BandDescription;
