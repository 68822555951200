const FeedbackMessage = ({
  isCorrect,
  isAttempted,
}: {
  isCorrect: boolean;
  isAttempted: boolean;
}) => {
  let message, borderColor, textColor;

  if (isCorrect) {
    message = "You gave correct answer for this question";
    borderColor = "border border-[#59A51A]";
    textColor = "text-[#59A51A]";
  } else if (!isAttempted) {
    message = "You haven't made an attempt to answer the question";
    borderColor = "border border-[#4E5664]";
    textColor = "text-[#4E5664]";
  } else {
    message = "You gave incorrect answer for this question";
    borderColor = "border border-[#DB3555]";
    textColor = "text-[#DB3555]";
  }

  return (
    <div
      className={`mt-10 ${textColor} ${borderColor} rounded-md px-3 py-2 w-3/4 font-avenir`}
    >
      {message}
    </div>
  );
};
export default FeedbackMessage;
