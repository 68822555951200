import { ReactNode } from "react";
import ErrorPage from "../../../pages/ErrorPage";
import ResultsSkeleton from "../../Skeletons/ResultsSkeleton";

type ConditionalRendererType = {
  isLoading: boolean;
  loaderStudent: any;
  dashboards: any;
  isError: any;
  children: ReactNode;
};
const ConditionalRenderer = ({
  isLoading,
  loaderStudent,
  dashboards,
  isError,
  children,
}: ConditionalRendererType) => {
  if (loaderStudent) {
    return (
      <div>
        <ResultsSkeleton />
      </div>
    );
  }

  if (!dashboards.includes(4004)) {
    return (
      <div>
        <ErrorPage />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="text-black font-bold text-center text-4xl m-auto">
        No Data Available
      </div>
    );
  }

  return (
    <div className="bg-[#F9FBFF] overflow-y-auto">
      {isLoading ? <ResultsSkeleton /> : children}
    </div>
  );
};

export default ConditionalRenderer;
