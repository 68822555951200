import { setControlAccordian } from "../../../../app/Slices/writingResultsSlice";
import { useDispatch } from "react-redux";
import upArrow from "../../../../assets/icons/upArrowAIfeedback.png";
import downArrow from "../../../../assets/icons/downArrowAIfeedback.png";

const Accordion = ({
  label,
  section,
  bool,
}: {
  label: string;
  section: string;
  bool: boolean;
}) => {
  const dispatch = useDispatch();
  return (
    <div className="text-[#E31837] text-lg font-semibold flex">
      {label}
      <button
        onClick={() =>
          dispatch(
            setControlAccordian({
              section: section,
              bool: !bool,
            })
          )
        }
        className={`${bool ? "pb-2" : "pt-2"} pl-2 cursor-pointer`}
      >
        <img src={bool ? upArrow : downArrow} alt="arrow" />
      </button>
    </div>
  );
};

export default Accordion;
