import Graph from "../../Graph";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../app/Store";
import { useEffect } from "react";
import {
  setLastPageWriting,
  setTestTypeWriting,
  setWritingCurrentPage,
  setWritingGraphData,
  setWritingResultData,
} from "../../../../app/Slices/writingResultsSlice";
import { useAppSelector } from "../../../../app/hooks";
import AttemptedTests from "../../AttemptedTests";
import { setActiveTab } from "../../../../app/Slices/studentSlice";
import { useWritingAiFeedBackResultsMutation } from "../../../../api/writingResultsApi";

const Writing = () => {
  const dispatch = useDispatch();
  const { writingGraphData } = useSelector(
    (state: RootState) => state.root.writingResults
  );
  const [writingAiFeedBackResults] = useWritingAiFeedBackResultsMutation();

  const series = [
    {
      type: "line",
      name: "Tests Graded",
      data: writingGraphData?.scores,
      color: "#FFECA9",
      marker: {
        fillColor: "#FFF",
        lineColor: "#DB991D",
      },
    },
  ];

  const categories = writingGraphData?.dates;

  const { writingResultData, currentPage, lastPageWriting } = useSelector(
    (state: RootState): any => state.root.writingResults
  );

  const { selectedStudentNumber } = useAppSelector(
    (state) => state.root.student
  );

  useEffect(() => {
    if (writingResultData?.testscore && selectedStudentNumber) {
      let graphData =
        writingResultData?.testscore?.[
          selectedStudentNumber as keyof typeof writingResultData.testscore
        ];
      dispatch(setWritingGraphData(graphData));
    }
  }, [selectedStudentNumber]);

  const tabs = [
    { id: "all", value: "All" },
    { id: "full", value: "Full Test" },
    { id: "part1", value: "Part 1" },
    { id: "part2", value: "Part 2" },
  ];

  useEffect(() => {
    dispatch(setActiveTab("writing"));
  }, [dispatch]);

  return (
    <>
      {writingResultData ? (
        <>
          <div className="w-full">
            <div className="col-span-2 border border-[#E3E5E9] rounded-lg -z-1">
              <Graph
                legendEnabled={false}
                categories={categories}
                title="Writing Test Score"
                series={series}
                tickInterval={3}
                min={0}
                max={9}
              />
            </div>
          </div>
          <AttemptedTests
            currentPage={currentPage}
            lastPage={lastPageWriting}
            data={writingResultData}
            tabs={tabs}
            setTestType={setTestTypeWriting}
            apiCall={writingAiFeedBackResults}
            setData={setWritingResultData}
            setLastPage={setLastPageWriting}
            setCurrentPage={setWritingCurrentPage}
          />
        </>
      ) : (
        <div className="flex justify-center text-xl text-black text-center">
          No Data Available
        </div>
      )}
    </>
  );
};

export default Writing;
