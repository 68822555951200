import { useRef, useState } from "react";
import Icon from "../../../../assets/icons/examinerPro.svg";
import PlayIcon from "../../../../assets/icons/PlayIcon.svg";

const PartBody = (props: any) => {
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(
    null
  );
  const audioRef = useRef<HTMLAudioElement>(null);

  const playAudio = (audioUrl: string, audioElement: HTMLAudioElement) => {
    // Stop the currently playing audio, if any
    if (currentAudio) {
      currentAudio.pause();
    }

    // Set the source and play the audio
    if (audioRef.current) {
      audioRef.current.src = audioUrl;
      audioRef.current.play();
      setCurrentAudio(audioRef.current);
    }
  };

  return (
    <div className="relative ">
      <div className="flex flex-row justify-start">
        <img src={Icon} alt="examinerImg" />
        <div className="border border-[#737E93] rounded-[16px] w-3/5 p-[8px] text-[#737E93] mx-[8px]">
          {" "}
          {props.item.question.text}
        </div>
        <button
          className="border border-[#737E93] rounded-full bg-[#E31837] flex justify-center items-center w-[20px] h-[20px] my-[20px]"
          onClick={() =>
            audioRef.current &&
            playAudio(props.item.question.audio_file, audioRef.current)
          }
        >
          <img src={PlayIcon} alt="playImg" />
          <audio ref={audioRef}>
            <track
              kind="captions"
              src="captions.vtt"
              srcLang="en"
              label="English"
              default
            />
          </audio>
        </button>
      </div>
      <div className="flex justify-end pt-[16px] pb-[8px] pr-[16px]">
        <button
          className="border border-[#737E93] rounded-full bg-[#E31837] flex justify-center items-center w-[20px] h-[20px] my-[16px]"
          onClick={() =>
            audioRef.current &&
            playAudio(props.item.answer.answer_audio, audioRef.current)
          }
        >
          <img src={PlayIcon} alt="playImg" />
          <audio ref={audioRef}>
            <track
              kind="captions"
              src="captions.vtt"
              srcLang="en"
              label="English"
              default
            />
          </audio>
        </button>
        <div className="border border-[#737E93] rounded-[16px] w-3/5 p-[8px] text-[#737E93] ml-[8px]">
          {props.item.answer.answer}
        </div>
      </div>
      {props.item.answer.pronunciation_score === 0 &&
        props.item.answer.fluency_score === 0 &&
        props.item.answer.grammar_score === 0 &&
        props.item.answer.vocabulary_score === 0 && (
          <div className="text-red-600 text-xs font-medium flex justify-end pr-4">
            This Answer is Irrelevant
          </div>
        )}
      <div className="flex justify-end pr-4 pb-[16px] text-[#737E93] text-[12px] mt-2 ">
        Pronunciation:
        {Math.round((props.item.answer.pronunciation_score / 9) * 100)}%
        Fluency:{Math.round((props.item.answer.fluency_score / 9) * 100)}%
        Grammar: {Math.round((props.item.answer.grammar_score / 9) * 100)}%
        Vocabulary:
        {Math.round((props.item.answer.vocabulary_score / 9) * 100)}%
      </div>
    </div>
  );
};
export default PartBody;
