import CustomButton from "../../Common/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../app/Slices/modalSlice";
import { toast } from "react-toastify";
import { useState } from "react";
import {
  useAllTutorsQuery,
  useUpdateTutorMutation,
} from "../../../api/institutionTutorApi";
import { RootState } from "../../../app/Store";
import { S3 } from "aws-sdk";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  setEmailError,
  setMobileNumberError,
} from "../../../app/Slices/authSlice";
import "../../../styles/phoneInput.css";
import awsConfig from "../../../awsConfig.json";
import CommonEdit from "./CommonEdit";
import { upload_file_to_s3 } from "../../../utils/utils";

const EditTutorForm = () => {
  const { selectedTutorId, selectedTutorDetails } = useSelector(
    (state: RootState) => state.root.tutor
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [tutorData, setTutorData] = useState(selectedTutorDetails);
  const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;

  const [updateTutor] = useUpdateTutorMutation();
  const { refetch } = useAllTutorsQuery({});
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTutorData({ ...tutorData, [name]: value });
    if (email && !emailPattern.test(email)) {
      dispatch(setEmailError("Please Enter Valid Email Ex:abc@domain.com"));
    } else {
      dispatch(setEmailError(""));
    }
  };
  const { name, email, mobileNumber, profilePhoto } = tutorData;
  const { emailError, mobileNumberError } = useSelector(
    (state: RootState) => state.root.auth
  );
  const dispatch = useDispatch();
  const onClickHandler = async () => {
    try {
      if (email && !emailPattern.test(email)) {
        dispatch(setEmailError("Please Enter Valid Email Ex:abc@domain.com"));
      } else if (mobileNumber && !isValidPhoneNumber(mobileNumber)) {
        dispatch(setMobileNumberError("Please Enter Valid Mobile Number"));
      } else {
        dispatch(setEmailError(""));
        dispatch(setMobileNumberError(""));
        const res = await updateTutor({
          id: selectedTutorId,
          payload: tutorData,
        });
        if ("error" in res && res.error && "data" in res.error) {
          toast.error("unable to update tutor details");
        } else {
          refetch();
          dispatch(closeModal());
          toast.success("Tutor Details Updated");
        }
      }
    } catch (error) {}
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let file = event.target.files;
    if (file && file.length > 0) {
      upload_file_to_s3(file[0]);
      setSelectedFile(file[0]);
    }
  };
  const handleChangeMobileNumber = (value: string) => {
    setTutorData({ ...tutorData, mobileNumber: "+" + value });
    if (value && !isValidPhoneNumber("+" + value)) {
      dispatch(setMobileNumberError("Please enter valid mobile number"));
    } else {
      dispatch(setMobileNumberError(""));
    }
  };
  return (
    <CommonEdit
      email={email ?? ""}
      emailError={emailError}
      handleChangeMobileNumber={handleChangeMobileNumber}
      handleFileChange={handleFileChange}
      mobileNumber={mobileNumber ?? ""}
      mobileNumberError={mobileNumberError}
      name={name}
      onChangeHandler={onChangeHandler}
      profilePhoto={profilePhoto}
      selectedFile={selectedFile}
    >
      <CustomButton
        disabled={name === "" || email === "" || mobileNumber === ""}
        name="Edit Details"
        onClickHandler={onClickHandler}
        width="w-full"
      />
    </CommonEdit>
  );
};

export default EditTutorForm;
