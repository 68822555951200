import { createSlice } from "@reduxjs/toolkit";
import { formatDateToCustomFormat } from "../../utils/reusableFunctions";
import { speakingResultsApi } from "../../api/speakingResultsApi";

interface SpeakingResultsState {
  accordian: {
    whatWentWrong: boolean;
    improvements: boolean;
    idealAnswer: boolean;
  };
  activeTabAiFeedback: number;
  showless: boolean;
  testId: string;
  userMobileNumber: string;
  testType: string;
  speakingResultsData: any;
  speakingResponseData: any;
  speakingLoader: boolean;
  testData: any;
  speakingGraphData: any;
  speakingFullTestDetailedReport: any;
  getQuestionAnalysisPartOne: any;
  getQuestionAnalysisPartTwo: any;
  getQuestionAnalysisPartThree: any;
  lastPageSpeaking: any;
  currentPage: number;
}

const initialState: SpeakingResultsState = {
  accordian: {
    whatWentWrong: false,
    improvements: false,
    idealAnswer: false,
  },
  activeTabAiFeedback: 0,
  showless: false,
  testId: "",
  userMobileNumber: "+919908479039",
  testType: "",
  speakingResultsData: {},
  speakingResponseData: {},
  speakingLoader: false,
  testData: "",
  speakingGraphData: { scores: [], dates: [] },
  speakingFullTestDetailedReport: {},
  getQuestionAnalysisPartOne: {},
  getQuestionAnalysisPartTwo: {},
  getQuestionAnalysisPartThree: {},
  lastPageSpeaking: "",
  currentPage: 1,
};
const speakingResultsSlice = createSlice({
  name: "speakingResults",
  initialState,
  reducers: {
    setTestType: (state, action) => {
      state.testType = action.payload;
    },
    setSpeakingResponseData: (state, action) => {
      state.speakingResponseData = action.payload;
    },
    setLastPageSpeaking: (state, action) => {
      state.lastPageSpeaking = action.payload;
    },

    setTestData: (state, action) => {
      state.testData = action.payload;
    },
    setSpeakingFullTestDetailedReport: (state, action) => {
      state.speakingFullTestDetailedReport = action.payload;
    },
    setSpeakingGraphData: (state, action) => {
      const transformedData = action.payload?.reduce(
        (result: any, item: any) => {
          result.scores.push(item.score);
          const dateObject = new Date(item.date);
          result.dates.push(formatDateToCustomFormat(dateObject));
          return result;
        },
        { scores: [] as string[], dates: [] as number[] }
      );
      state.speakingGraphData = transformedData;
    },
    setGetQuestionAnalysisPartOne: (state, action) => {
      state.getQuestionAnalysisPartOne = action.payload;
    },

    setGetQuestionAnalysisPartTwo: (state, action) => {
      state.getQuestionAnalysisPartTwo = action.payload;
    },
    setGetQuestionAnalysisPartThree: (state, action) => {
      state.getQuestionAnalysisPartThree = action.payload;
    },
    setSpeakingCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    resetSpeakingResults: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      speakingResultsApi.endpoints.fetchSpeakingTestChart.matchFulfilled,
      (state, action) => {
        const transformedData = action.payload?.reduce(
          (result: any, item: any) => {
            result.scores.push(item.score);
            const dateObject = new Date(item.date);
            result.dates.push(formatDateToCustomFormat(dateObject));
            return result;
          },
          { scores: [] as string[], dates: [] as number[] }
        );
        state.speakingGraphData = transformedData;
      }
    );
  },
});

export const {
  setTestType,
  setLastPageSpeaking,
  setSpeakingResponseData,
  setTestData,
  setSpeakingGraphData,
  setSpeakingFullTestDetailedReport,
  setGetQuestionAnalysisPartOne,
  setGetQuestionAnalysisPartTwo,
  setGetQuestionAnalysisPartThree,
  resetSpeakingResults,
  setSpeakingCurrentPage,
} = speakingResultsSlice.actions;

export default speakingResultsSlice.reducer;
