const AnswersDisplay = ({
  userAnswer,
  exactAnswer,
  isCorrect,
  isAttempted,
}: any) => {
  const userAnswerDisplay = Array.isArray(userAnswer) ? (
    <ul>
      {userAnswer.map((item: string) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  ) : (
    userAnswer || "Not Attempted"
  );
  const exactAnswerDisplay = Array.isArray(exactAnswer) ? (
    <ul>
      {exactAnswer?.map((item: string) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  ) : (
    exactAnswer
  );

  const determineClassName = (isCorrect: boolean, isAttempted: boolean) => {
    if (isCorrect) {
      return "text-[#59A51A]";
    } else if (!isAttempted) {
      return "text-[#4E5664]";
    } else {
      return "text-[#DB3555]";
    }
  };
  const className = determineClassName(isCorrect, isAttempted);

  console.log(userAnswerDisplay, "userAnswerDisplay");

  return (
    <div>
      <p className="text-[#737E93] uppercase my-2 mt-10 font-semibold text-sm font-avenir">
        Your Answer
      </p>
      <p className={`${className} text-sm font-avenir`}>{userAnswerDisplay}</p>
      <p className="text-[#737E93] uppercase mb-2 mt-5">Correct Answer</p>
      <p className="text-[#59A51A] text-sm font-avenir">{exactAnswerDisplay}</p>
    </div>
  );
};

export default AnswersDisplay;
