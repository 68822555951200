import { useEffect } from "react";
import { useLazyAllTutorsQuery } from "../api/institutionTutorApi";
import { useAppSelector } from "../app/hooks";
import TutorDetails from "../components/Tutor/TutorDetails";
import TutorsList from "../components/Tutor/TutorsList";
import {
  setSearchValue,
  setSortColumnNumber,
  setSortTypeNumber,
} from "../app/Slices/filtersSlice";
import { useDispatch } from "react-redux";
import { setItem } from "../utils/storage";
import Loader from "../components/Common/CustomLoader";

const Tutors = () => {
  const { searchValue } = useAppSelector((state) => state.root.filters);
  const [allTutors, { currentData, isFetching, isLoading }] =
    useLazyAllTutorsQuery({});
  const dispatch = useDispatch();

  useEffect(() => {
    setItem("activeMenu", "/tutors");
  }, []);

  useEffect(() => {
    if (!searchValue) {
      allTutors({});
    }
  }, [searchValue]);

  useEffect(() => {
    if (isFetching) {
      dispatch(setSearchValue(""));
      dispatch(setSortColumnNumber(0));
      dispatch(setSortTypeNumber(1));
    }
  }, [isFetching]);

  return (
    <>
      {currentData?.length > 0 && !isLoading ? (
        <div className="grid grid-cols-1 md:grid-cols-3 bg-white min-h-screen">
          <div className="pt-4 border-r border-r-[#E3E5E9]">
            <TutorsList />
          </div>
          <div className="col-span-1 md:col-span-2">
            <TutorDetails />
          </div>
        </div>
      ) : (
        <p className="flex justify-center items-center mt-10">
          No Tutors Found
        </p>
      )}
      {isLoading && (
        <p className="">
          <Loader isLoading={true} />
        </p>
      )}
    </>
  );
};

export default Tutors;
