import { getItem } from "../../../../utils/storage";

const SubmissionInfo = () => {
  const selectedStudentName = getItem("selectedStudentName");
  const selectedStudentSubmitDate = getItem("selectedStudentSubmitDate");
  return (
    <div className="flex justify-between items-center shadow-sm bg-[#F8F9FA] text-sm mb-6 py-4 px-10 text-primaryColor">
      <p>
        Submitted by:{" "}
        <span className="font-extrabold text-[#292D35]">
          {selectedStudentName}
        </span>
      </p>
      <p>
        Submitted on:{" "}
        <span className="font-extrabold text-[#292D35]">
          {new Date(selectedStudentSubmitDate ?? "N/A").toLocaleDateString(
            "en-IN",
            {
              month: "short",
              day: "2-digit",
              year: "numeric",
            }
          )}
        </span>
      </p>
      <p>
        Graded by: <span className="font-extrabold text-[#292D35]">N/A</span>
      </p>
      <p>
        Graded on: <span className="font-extrabold text-[#292D35]">N/A</span>{" "}
      </p>
    </div>
  );
};

export default SubmissionInfo;
