const RenderParagraph = ({ paragraph }: { paragraph: string }) => {
  const regex = /{qId: (\d+)}./g;
  const hasQId = regex.test(paragraph);

  if (hasQId) {
    return (
      <>
        {paragraph.split(regex).map((part: any, index: any) => {
          if (index % 2 === 0) {
            return <span key={part}>{part}</span>;
          } else {
            const qId = part;
            return (
              <div
                key={qId}
                className="rounded-lg p-1 my-1 text-center text-[#62656a] border border-[#b3b8c2]"
              >
                <p>Question: {qId}</p>
              </div>
            );
          }
        })}
      </>
    );
  } else {
    return <span>{paragraph}</span>;
  }
};

export default RenderParagraph;
