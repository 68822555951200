import { FilterType } from "./FiltersComponent";

export const generateRadios = (
  onChangeRadioHandler: (event: React.ChangeEvent<HTMLInputElement>) => void,
  filters: any,
  type: "student" | "tutor"
): FilterType[] => {
  const commonRadios = [
    {
      value: "1",
      label: "Name",
      name: "name",
      onChangeHandler: onChangeRadioHandler,
      checked: filters?.sortColumnNumber === 1,
    },
    {
      value: "2",
      label: "Date Joined",
      name: "dateJoined",
      onChangeHandler: onChangeRadioHandler,
      checked: filters?.sortColumnNumber === 2,
    },
  ];

  const studentRadios = [
    {
      value: "3",
      label: "Band Score",
      name: "bandScore",
      onChangeHandler: onChangeRadioHandler,
      checked: filters?.sortColumnNumber === 3,
    },
    {
      value: "4",
      label: "Attempted Tests",
      name: "attemptedTests",
      onChangeHandler: onChangeRadioHandler,
      checked: filters?.sortColumnNumber === 4,
    },
  ];

  const tutorRadios = [
    {
      value: "3",
      label: "# Students",
      name: "students",
      onChangeHandler: onChangeRadioHandler,
      checked: filters?.sortColumnNumber === 3,
    },
    {
      value: "4",
      label: "# Graded Tests",
      name: "gradedTests",
      onChangeHandler: onChangeRadioHandler,
      checked: filters?.sortColumnNumber === 4,
    },
    {
      value: "5",
      label: "# Pending Tests",
      name: "pendingTests",
      onChangeHandler: onChangeRadioHandler,
      checked: filters?.sortColumnNumber === 5,
    },
  ];

  return type === "student"
    ? commonRadios.concat(studentRadios)
    : commonRadios.concat(tutorRadios);
};
