const storage = localStorage;

export function setItem(key: any, value: any) {
    storage.setItem(key, value);
}

export function getItem(key: any, default_val: any = '') {
    try {
        return storage.getItem(key);
    } catch (e) {
        // @ts-ignore
        console.error(e.toString())
    }
    return default_val
}

export function clearCahce() {
    storage.clear();
}
