import { createSlice } from "@reduxjs/toolkit";
import { formatDateToCustomFormat } from "../../utils/reusableFunctions";
import { listeningResultsApi } from "../../api/listeningResultsApi";

interface ListeningResultsState {
  aiFeedback: {
    partType: string;
    paragraphTitle: string;
    paragraphContent: string;
    questionId: string;
    questionName: string;
    questionType: string;
    userAnswer: string;
    exactAnswer: string;
    isCorrect: boolean;
    isSelected: boolean;
  };
  listeningResultsData: any;
  listeningGraphData: any;
  listeningAiFeedback: any;
  getListeningTestScorePartOne: any;
  getListeningTestScorePartTwo: any;
  getListeningTestScorePartThree: any;
  getListeningTestScorePartFour: any;
  listeningFullTestDetailedReport: any;
  currentPage: number;
  listeningLastPage: null | number;
}

const initialState: ListeningResultsState = {
  aiFeedback: {
    partType: "",
    paragraphTitle: "",
    paragraphContent: "",
    questionId: "",
    questionName: "",
    questionType: "",
    userAnswer: "",
    exactAnswer: "",
    isCorrect: false,
    isSelected: false,
  },
  listeningResultsData: [],
  listeningGraphData: { scores: [], dates: [] },
  listeningAiFeedback: {},
  getListeningTestScorePartOne: {},
  getListeningTestScorePartTwo: {},
  getListeningTestScorePartThree: {},
  listeningFullTestDetailedReport: {},
  getListeningTestScorePartFour: {},
  currentPage: 1,
  listeningLastPage: null,
};
const listeningResultsSlice = createSlice({
  name: "listeningResults",
  initialState,
  reducers: {
    setAiFeedBack: (state, action) => {
      state.aiFeedback = {
        ...state.aiFeedback,
        ...action.payload,
      };
    },
    setListeningResultsData: (state, action) => {
      state.listeningResultsData = action.payload;
    },
    setListeningAiFeedback: (state, action) => {
      state.listeningAiFeedback = action.payload;
    },
    setListeningTestScorePartOne: (state, action) => {
      state.getListeningTestScorePartOne = action.payload;
    },
    setListeningTestScorePartTwo: (state, action) => {
      state.getListeningTestScorePartTwo = action.payload;
    },
    setListeningTestScorePartThree: (state, action) => {
      state.getListeningTestScorePartThree = action.payload;
    },
    setListeningTestScorePartFour: (state, action) => {
      state.getListeningTestScorePartFour = action.payload;
    },
    setListeningFullTestDetailedReport: (state, action) => {
      state.listeningFullTestDetailedReport = action.payload;
    },
    setListeningCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setListeningLastPage: (state, action) => {
      state.listeningLastPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      listeningResultsApi.endpoints.fetchListeningTestChart.matchFulfilled,
      (state, action) => {
        const transformedData = action.payload?.reduce(
          (result: any, item: any) => {
            result.scores.push(item.score);
            const dateObject = new Date(item.date);
            result.dates.push(formatDateToCustomFormat(dateObject));
            return result;
          },
          { scores: [] as string[], dates: [] as number[] }
        );
        state.listeningGraphData = transformedData;
      }
    );
  },
});

export const {
  setListeningResultsData,
  setListeningAiFeedback,
  setListeningTestScorePartOne,
  setListeningTestScorePartTwo,
  setListeningTestScorePartThree,
  setListeningTestScorePartFour,
  setListeningFullTestDetailedReport,
  setAiFeedBack,
  setListeningCurrentPage,
  setListeningLastPage,
} = listeningResultsSlice.actions;

export default listeningResultsSlice.reducer;
