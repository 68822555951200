import CircularProgressBar from "../Common/CircularProgressBar";
import icon from "../../assets/images/user.svg";

const StudentsListFilteredResults = (props: any) => {
  const {
    tabId,
    currentData,
    selectedStudentDetails,
    handleStudentClicked,
    searchValue,
    userMobileNumbers,
  } = props;

  const getFilteredResults = () => {
    if (!currentData?.allstudents) return [];

    if (searchValue) {
      return currentData.allstudents.filter((item: any) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    switch (tabId) {
      case "new":
        return filterNewStudents(currentData.allstudents);
      case "unassigned":
      case "part2":
        return currentData.allstudents.filter(
          (item: any) => item.tutorId === "null"
        );
      case "all":
      default:
        return currentData.allstudents;
    }
  };

  const filterNewStudents = (students: any[]) => {
    const currentTime = new Date().getTime();
    return students.filter((record: any) => {
      const createdDate = new Date(record.createdDate).getTime();
      const hoursDifference = (currentTime - createdDate) / (1000 * 3600);
      return hoursDifference <= 24;
    });
  };

  let filteredResults = getFilteredResults();
  let mobileNumber = [];
  if (filteredResults && filteredResults.length > 0) {
    mobileNumber = filteredResults?.map((item: any) => item.mobileNumber);
  }

  if (mobileNumber.length > 0) {
    userMobileNumbers.current = mobileNumber;
  }

  return (
    <div className="bg-white">
      {filteredResults && filteredResults.length > 0 ? (
        filteredResults.map((item: any, index: any) => (
          <div key={item._id} className="w-full">
            <button
              onClick={() => handleStudentClicked(index, item._id, item)}
              className={`flex w-full justify-between py-4 cursor-pointer items-center hover:bg-[#FFF1ED] rounded-xl ${
                selectedStudentDetails._id === item._id
                  ? "bg-[#FFF1ED] rounded-xl"
                  : ""
              }`}
            >
              <div className="flex items-center pl-4">
                <img
                  className="mr-4 w-10 h-10"
                  src={item?.profilePhoto ? item?.profilePhoto : icon}
                  alt="img"
                />
                <h5 className="text-base text-primaryColor">{item?.name}</h5>
              </div>

              <div className="w-12 mr-4">
                <CircularProgressBar score={[item.overAllBandScore]} />
              </div>
            </button>
          </div>
        ))
      ) : (
        <div className="text-center my-4">No results found</div>
      )}
    </div>
  );
};

export default StudentsListFilteredResults;
