import "react-circular-progressbar/dist/styles.css";
import Tab from "../components/Common/Tab";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../app/hooks";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { setTestId } from "../app/Slices/tutorSlice";
import { getItem } from "../utils/storage";
import { setTestType } from "../app/Slices/speakingResultsSlice";
import { setSelectedStudentMobileNumber } from "../app/Slices/studentSlice";
import axios from "axios";
import { useListeningAiFeedBackResultsMutation } from "../api/listeningResultsApi";
import {
  setListeningAiFeedback,
  setListeningFullTestDetailedReport,
  setListeningResultsData,
  setListeningTestScorePartFour,
  setListeningTestScorePartOne,
  setListeningTestScorePartThree,
  setListeningTestScorePartTwo,
} from "../app/Slices/listeningResultsSlice";
import { setLoader } from "../app/Slices/loaderSlice";
import { ielts_url } from "../utils/apiConfig";
import ListeningTestResults from "../components/Students/AIFeedBack/Listening/ListeningTestResults";
import ListeningAIFeedback from "../components/Students/AIFeedBack/Listening/ListeningAIFeedback";
import SubmissionInfo from "../components/Students/AIFeedBack/SubmissionInfoHeader";
import ConditionalRenderer from "../components/Students/AIFeedBack/ConditionalResultsRender";

const ListeningTestResultsMain = () => {
  const testType = getItem("testType");
  const { id } = useParams();
  const dispatch = useDispatch();
  const { listeningResultsData } = useAppSelector(
    (state) => state.root.listeningResults
  );
  const { dashboards, loaderStudent } = useAppSelector(
    (state) => state.root.student
  );
  const { isLoading } = useAppSelector((state) => state.root.loader);
  const mobile = getItem("mobileNo");
  const test = getItem("testType");
  const mobileNumbers = getItem("mobileNumbers");

  const [listeningAiFeedBackResults, { data: listeningData }] =
    useListeningAiFeedBackResultsMutation();

  const userId = getItem("userId");
  const [listeningError, setListeningError] = useState(false);
  const fetchListeningTestResults = async () => {
    dispatch(setLoader(true));
    try {
      const res = await axios.get(
        `${ielts_url}/api/get-listening-test-score-tutor?test_id=${id}&user_id=${userId}`
      );
      if (res.status === 200) {
        setListeningError(false);
        dispatch(setLoader(false));
        dispatch(setListeningTestScorePartOne(res.data["part1"]));
        dispatch(setListeningTestScorePartTwo(res.data["part2"]));
        dispatch(setListeningTestScorePartThree(res.data["part3"]));
        dispatch(setListeningTestScorePartFour(res.data["part4"]));
        dispatch(setListeningFullTestDetailedReport(res.data));
      }
    } catch (error) {
      setListeningError(true);
      dispatch(setLoader(false));
      console.log(error, "error fetching listening test results");
    }
  };

  const getListeningTestQuestionAnalysis = async () => {
    try {
      dispatch(setLoader(true));
      const res = await axios.get(
        `${ielts_url}/api/get-listening-question-analysis-tutor?test_id=${id}&user_id=${userId}`
      );
      if (res.status === 200) {
        dispatch(setLoader(false));
        dispatch(setListeningAiFeedback(res.data));
      }
    } catch (error) {
      dispatch(setLoader(false));
      console.log(error, "error fetching listening test results");
    }
  };
  useEffect(() => {
    if (id && userId) {
      getListeningTestQuestionAnalysis();
      fetchListeningTestResults();
    }
  }, [id, userId]);

  useEffect(() => {
    if (Object.keys(listeningResultsData).length === 0) {
      if (mobileNumbers && testType !== "full") {
        listeningAiFeedBackResults({
          payload: JSON.parse(mobileNumbers),
          page: 1,
        });
      }
      if (mobile && testType !== "full") {
        listeningAiFeedBackResults({ payload: [mobile], page: 1 });
      }
    }
  }, []);

  useEffect(() => {
    if (listeningData) {
      dispatch(setListeningResultsData(listeningData));
    }
  }, [dispatch, listeningData]);

  useEffect(() => {
    if (id) {
      dispatch(setTestId(id));
    }
    if (mobile) {
      dispatch(setSelectedStudentMobileNumber(mobile));
    }
    if (test) {
      dispatch(setTestType(test));
    }
  }, [dispatch, id, mobile, test]);

  return (
    <ConditionalRenderer
      isLoading={isLoading}
      loaderStudent={loaderStudent}
      dashboards={dashboards}
      isError={listeningError}
    >
      <>
        <SubmissionInfo />
        <div className="px-10 ">
          <Tab
            classes={""}
            activeClass={"text-[red] border-b-[3px] border-secondaryColor"}
            getActiveTab={(activeTabId) => {}}
            tabs={[
              { id: "result", value: "Result" },
              { id: "aiFeedback", value: "AI Feedback" },
            ]}
          >
            <Tab.Body id={"result"}>
              <div className="mt-5">
                <ListeningTestResults />
              </div>
            </Tab.Body>
            <Tab.Body id="aiFeedback">
              <div className="flex justify-between items-center">
                <ListeningAIFeedback />
              </div>
            </Tab.Body>
          </Tab>
        </div>
      </>
    </ConditionalRenderer>
  );
};

export default ListeningTestResultsMain;
