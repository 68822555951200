import { useDispatch } from "react-redux";
import TabContent from "./TabContent";
import { getItem } from "../../../utils/storage";
import { useAppSelector } from "../../../app/hooks";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import Tab from "../../Common/Tab";

const AttemptedTests = ({
  tabs,
  data,
  currentPage,
  lastPage,
  setData,
  setLastPage,
  setCurrentPage,
  apiCall,
  setTestType,
}: any) => {
  const dispatch = useDispatch();
  const { selectedStudentDetails } = useAppSelector(
    (state) => state.root.student
  );
  const goToPage = async (pageNumber: number) => {
    try {
      const res = await apiCall({
        payload: [selectedStudentDetails?.mobileNumber],
        page: pageNumber,
      }).unwrap();
      dispatch(setData(res.data));
      dispatch(setLastPage(res.lastPageNumber));
      dispatch(setCurrentPage(pageNumber));
    } catch (error) {
      console.log(error);
    }
  };
  const renderTabContent = (tabId: string) => {
    let filteredResults: any[] = [];
    let filterSelectedStudentData = data?.filter(
      (item: any) => item.mobileNumber === getItem("mobileNo")
    );

    if (tabId === "all") {
      filteredResults = filterSelectedStudentData;
    } else {
      filteredResults = filterSelectedStudentData.filter(
        (item: any) => item.testType === tabId
      );
    }
    if (filteredResults.length === 0) {
      return <p className="mt-4">No data available</p>;
    }
    return <TabContent filteredResults={filteredResults} />;
  };
  return (
    <div>
      <p className="text-base text-primaryColor mt-6 mb-4">Attempted Tests</p>
      <Tab
        classes="border border-[#E3E5E9] rounded-full px-4 py-1 text-grey text-xs"
        activeClass="border border-secondaryColor bg-[#FFEAEA] px-4 py-1 rounded-full text-[red] text-xs"
        getActiveTab={(activeTab) => dispatch(setTestType(activeTab))}
        tabs={tabs}
      >
        {tabs.map((tab: any) => (
          <Tab.Body key={tab.id} id={tab.id}>
            {renderTabContent(tab.id)}
          </Tab.Body>
        ))}
      </Tab>
      {data?.length > 0 && (
        <div className="flex items-center gap-4 justify-center pb-20">
          <button
            onClick={() => goToPage(currentPage - 1)}
            disabled={currentPage === 1}
            className={`border ${
              currentPage === 1
                ? "border-gray-300 text-gray-300"
                : "border-black text-black"
            } px-3 py-2 rounded-md`}
          >
            <FaAngleLeft />
          </button>
          <span>Page {lastPage && lastPage > 0 ? currentPage : 0}</span>
          <button
            onClick={() => goToPage(currentPage + 1)}
            disabled={currentPage === lastPage || lastPage === 0}
            className={`border ${
              currentPage === lastPage || lastPage === 0
                ? "border-gray-300 text-gray-300"
                : "border-black text-black"
            } px-3 py-2 rounded-md`}
          >
            <FaAngleRight />
          </button>
        </div>
      )}
    </div>
  );
};

export default AttemptedTests;
