import { getClassName } from "../../../utils/utils";

export type PartWiseQuestionsType = {
  part: string;
  partData: any;
  aiFeedback: any;
  handleAIFeedback: any;
  setAIFeedback: any;
};

const PartWiseListeningQuestions = ({
  part,
  partData,
  aiFeedback,
  handleAIFeedback,
  setAIFeedback,
}: PartWiseQuestionsType) => {
  return (
    <div className="mt-3 px-4">
      <h1 className="mb-2 font-medium text-[#292D35] text-base font-avenir">
        {part}
      </h1>
      <div className="flex flex-wrap gap-4 mb-4 font-avenir">
        {partData?.questions.length > 0 ? (
          partData?.questions.map((item: any) => {
            const className = getClassName(item, aiFeedback, part);
            return (
              <button
                key={item.qId}
                className={className}
                onClick={() =>
                  handleAIFeedback(
                    item,
                    part.toLowerCase(),
                    partData?.audio,
                    setAIFeedback
                  )
                }
              >
                {item.qId}
              </button>
            );
          })
        ) : (
          <span className="text-center text-[#737E93]"> No Data Available</span>
        )}
      </div>
    </div>
  );
};

export default PartWiseListeningQuestions;
