import {
  forwardRef,
  useRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { Editor } from "@tinymce/tinymce-react";
import "./editortutor.css";

const EditorTutor = forwardRef((props: any, ref) => {
  const { initial } = props;
  const [content, setContent] = useState(initial);
  const editorNode = useRef(null);

  useEffect(() => {
    setContent(initial);
  }, [initial]);

  useImperativeHandle(ref, () => ({
    getContent() {
      try {
        // @ts-ignore
        const html = editorNode.current.getContent();
        return html;
      } catch (e) {
        console.error(e);
      }
      return initial;
    },
  }));

  return (
    <div>
      <Editor
        ref={editorNode}
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat ",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
        value={content}
        onEditorChange={(newText, editor) => {
          // @ts-ignore
          editorNode.current = editor;
          setContent(newText);
        }}
      />
    </div>
  );
});

export default EditorTutor;
