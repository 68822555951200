import CustomButton from "../../Common/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../app/Slices/modalSlice";
import { toast } from "react-toastify";
import {
  useAllTutorsQuery,
  useArchiveTutorMutation,
} from "../../../api/institutionTutorApi";
import { RootState } from "../../../app/Store";
import {
  setSelectedTutor,
  setSelectedTutorDetails,
} from "../../../app/Slices/tutorSlice";

const successToastStyle = {
  color: "#292D35",
  fontSize: "12px",
};

const additionalMessageStyle = {
  fontStyle: "italic",
  marginTop: "2px",
  color: "#737E93",
  fontSize: "10px",
};

const ArchiveTutor = () => {
  const dispatch = useDispatch();
  const [archiveTutor] = useArchiveTutorMutation();
  const { refetch } = useAllTutorsQuery({});
  const { selectedTutorId, selectedTutorDetails } = useSelector(
    (state: RootState) => state.root.tutor
  );
  const onClickHandler = async () => {
    try {
      const res = await archiveTutor(selectedTutorId);
      if ("error" in res && res.error && "data" in res.error) {
        toast.error("Unable to archived tutor");
      } else {
        refetch();
        dispatch(closeModal());
        toast.success(
          <>
            <div style={successToastStyle}>Tutor Archived Successfully</div>
            <div style={additionalMessageStyle}>
              {selectedTutorDetails?.name} successfully archived
            </div>
          </>,
          {
            autoClose: 5000,
          }
        );
        dispatch(
          setSelectedTutorDetails({
            _id: "",
            name: "",
            noOfStudentsAssigned: "",
            noOfTestsGraded: "",
            noOfTestsPending: "",
          })
        );
        dispatch(setSelectedTutor(""));
      }
    } catch (error) {}
  };
  return (
    <>
      <div className="mt-4 flex justify-center flex-col px-6">
        <p className="text-base text-primaryColor">
          Are you sure you want to archive {selectedTutorDetails?.name}?
        </p>
        <p className="text-base text-grey mt-3 mb-6">
          Note: This action will unassign all the students assigned to the
          tutor.
        </p>
      </div>
      <div className="mt-4 flex justify-center border-t border-t-[#E3E5E9] py-4">
        <div className="px-6 w-full">
          <CustomButton
            name="ARCHIVE TUTOR"
            onClickHandler={onClickHandler}
            width="w-full"
          />
        </div>
      </div>
    </>
  );
};

export default ArchiveTutor;
