import { useMemo } from "react";
import CustomSearch from "../../Common/CustomSearch";
import CustomButton from "../../Common/CustomButton";
import { useAppSelector } from "../../../app/hooks";
import Table from "../../Common/CustomTable";
import { useDispatch } from "react-redux";
import {
  setSelectedRows,
  setTutorListReAssign,
} from "../../../app/Slices/tutorSlice";
import TutorFilters from "../../Common/FiltersDropDown/tutorFilters";
import { setSearchValueStudent } from "../../../app/Slices/filtersSlice";
import {
  useAllTutorsQuery,
  useLazyAllTutorsQuery,
} from "../../../api/institutionTutorApi";
import { tutorColumn } from "../../../utils/utils";
import useFilters from "../../CustomHooks/useFilters";

const ReAssignTutor = ({
  reAssignBulkStudents,
}: {
  reAssignBulkStudents: any;
}) => {
  const [allTutors] = useLazyAllTutorsQuery();
  const {
    clearFilters,
    filters,
    handleAscendingSorting,
    handleDescendingSorting,
    onChangeRadioHandler,
  } = useFilters(allTutors);
  const { selectedTutorDetails, tutorsListReAssign, selectedRows } =
    useAppSelector((state) => state.root.tutor);
  const { searchValueStudent } = useAppSelector((state) => state.root.filters);
  const { currentData: tutorData } = useAllTutorsQuery({});
  const dispatch = useDispatch();

  const getSelectedData = (data: any) => {
    const tutorId = data.map((item: any) => item._id);
    dispatch(
      setSelectedRows({
        studentIds: selectedRows.studentIds,
        tutorId: tutorId[0],
      })
    );
  };

  const disableCheckBoxes = () => {
    if (!selectedRows.tutorId) return [];
    return tutorsListReAssign
      .filter((item: any) => item._id !== selectedRows.tutorId)
      .map((each) => each._id);
  };

  const getTutorsData = useMemo(() => {
    return tutorsListReAssign?.filter(
      (item: any) => item._id !== selectedTutorDetails._id
    );
  }, [tutorsListReAssign, selectedTutorDetails._id]);

  return (
    <div>
      <div className="bg-[#F8F9FA] py-[10px] px-5">
        <p>
          Currently Assigned to:{" "}
          <span className="font-bold">{selectedTutorDetails.name}</span>
        </p>
      </div>

      <div className="px-6 flex items-center pt-6">
        <CustomSearch
          value={searchValueStudent}
          handleSearchChange={(e) => {
            dispatch(setSearchValueStudent(e.target.value));
            const searchFilter = tutorData?.filter((item: any) => {
              if (item._id !== selectedTutorDetails._id) {
                return item.name
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase());
              }
            });

            dispatch(setTutorListReAssign(searchFilter));
          }}
        />
        <TutorFilters
          handleDescendingSorting={handleDescendingSorting}
          handleAscendingSorting={handleAscendingSorting}
          onChangeRadioHandler={onChangeRadioHandler}
          filters={filters}
          clearFilters={clearFilters}
        />
      </div>

      <div className="overflow-auto px-6">
        <Table
          columns={tutorColumn}
          data={getTutorsData}
          getSelectedData={getSelectedData}
          disableCheckBoxes={disableCheckBoxes()}
          disableHeaderCheckbox
        />
      </div>

      <div className="border-t border-t-[#E3E5E9] px-10 py-2 fixed bottom-0 2xl:w-[35%] xl:w-[40%] lg-[45%] md:w-[50%] sm:[50%] w-[80%] bg-white">
        <CustomButton
          width="w-full"
          name="REASSIGN STUDENT(s)"
          onClickHandler={reAssignBulkStudents}
          disabled={getTutorsData?.length === 0 || !selectedRows?.tutorId}
        />
      </div>
    </div>
  );
};

export default ReAssignTutor;
