const AiFeedbackQuestionDescription = ({ questionType }: any) => {
  switch (questionType?.toLowerCase()) {
    case "multiple choice (more than one answer)":
      return (
        <div className="text-[#3C424C] text-sm font-avenir mb-4 ">
          {" "}
          Choose the <strong>Multiple Correct Answers</strong>
        </div>
      );

    case "multiple choice":
      return (
        <div className="text-[#3C424C] text-sm font-avenir mb-4">
          Choose the <strong>Single Correct Answer</strong>
        </div>
      );
    case "note completion":
      return (
        <div className="text-[#3C424C] text-sm font-avenir mb-4">
          Complete the notes. Write{" "}
          <span className="spanclasses">ONE WORD ONLY</span>{" "}
          <span>from the text for each answer.</span>
        </div>
      );

    case "identifying information (true/false/not given)":
      return (
        <div className="text-[#3C424C] text-sm my-2 font-avenir mb-4">
          Choose <span className="font-bold">TRUE</span>
          <span>
            if the statement agrees with the information given in the text,
            choose
          </span>
          <span className="font-bold">FALSE</span>
          <span>if the statement contradicts the information, or choose</span>
          <span className="font-bold">NOT GIVEN</span>
          <span>if there is information on this.</span>
        </div>
      );

    case "summary completion (selecting word from text)":
      return (
        <div className="text-[#3C424C] text-sm font-avenir mb-4">
          Complete the summary. Write{" "}
          <span className="spanclasses">NO MORE THAN TWO WORDS</span>{" "}
          <span>from the text for each answer:</span>
        </div>
      );

    case "summary completion (selecting from a list of words or phrases)":
      return (
        <div className="text-[#3C424C] text-sm font-avenir mb-4">
          Complete the summary using the list of words. Choose the correct
          answer and drag & drop it into the field
        </div>
      );

    case "sentence completion":
      return (
        <div className="text-[#3C424C] text-sm my-2 font-avenir mb-4">
          Complete the notes. Write{" "}
          <span className="spanclasses">ONE WORD ONLY</span>{" "}
          <span>from the text for each answer.</span>
        </div>
      );

    case "sentence endings":
      return (
        <div className="text-[#3C424C] text-sm font-avenir mb-4">
          Complete each <strong>Sentence with the correct ending</strong>.
          Choose the correct answer and drag and drop it into the field.
        </div>
      );

    case "table completion":
      return (
        <div className="text-[#3C424C] text-sm mb-5 font-avenir">
          Complete the table. Write{" "}
          <span className="spanclasses">NO MORE THAN THREE WORDS </span>{" "}
          <span>from the text for each answer.</span>
        </div>
      );

    case "matching features":
      return (
        <div className="text-[#3C424C] text-sm font-avenir mb-4">
          Choose the correct group, You may choose any group more than once.
        </div>
      );

    case "short answer":
      return (
        <div className="text-[#3C424C] text-sm font-avenir mb-4 ">
          Answer the question.Write{" "}
          <strong className="spanclasses"> One Word Only</strong>{" "}
          <span>in each field</span>
        </div>
      );

    default:
      return null;
  }
};

export default AiFeedbackQuestionDescription;
