import { getClassName } from "../../../../utils/utils";
import { PartWiseQuestionsType } from "../PartWiseListeningQuestions";

const PartwiseReadingQuestions = ({
  partData,
  aiFeedback,
  handleAIFeedback,
  part,
  setAIFeedback,
}: PartWiseQuestionsType) => {
  return (
    <div>
      <div className="mt-3 px-4">
        <h1 className="mb-2 font-medium text-[#292D35] text-base font-avenir">
          {part}
        </h1>
        <div className="flex flex-wrap gap-4 mb-4 w-full">
          {partData?.questions.length > 0 &&
            partData?.questions.map((item: any) => {
              const className = getClassName(item, aiFeedback, part);
              return (
                <button
                  key={item.qId}
                  className={className}
                  onClick={() =>
                    handleAIFeedback(
                      item,
                      part.toLowerCase(),
                      "",
                      setAIFeedback,
                      partData
                    )
                  }
                >
                  {item.qId}
                </button>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default PartwiseReadingQuestions;
