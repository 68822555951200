import { createSlice } from "@reduxjs/toolkit";
import { profileApi } from "../../api/profileApi";

interface ProfileDetails {
  profileData: {
    name: string;
    email: string;
    mobileNumber: string;
    profilePhoto: string;
    orgName: string;
  };
}
const initialState: ProfileDetails = {
  profileData: {
    name: "",
    email: "",
    mobileNumber: "",
    profilePhoto: "",
    orgName: "",
  },
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },

    resetProfileData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      profileApi.endpoints.userProfile.matchFulfilled,
      (state, action) => {
        state.profileData = action.payload;
      }
    );
  },
});
export const { setProfileData, resetProfileData } = profileSlice.actions;
export default profileSlice.reducer;
