import "react-circular-progressbar/dist/styles.css";
import { RootState } from "../../../../app/Store";
import { useSelector } from "react-redux";
import Tabs from "../../../Common/WritingResultsTab";
import ReportFullTestCardDetails from "./fullTestreportFullDetails";

const DetailedFullTestResultsWriting = () => {
  let partOneData: any;
  let partTwoData: any;

  const { writingFullTestScoreData, testType } = useSelector(
    (state: RootState) => state.root.writingResults
  );
  partOneData = writingFullTestScoreData.part1;
  partTwoData = writingFullTestScoreData.part2;
  const tabs = [
    {
      label: "Part 1",
      partType: "part1",
      content: (
        <div>
          <ReportFullTestCardDetails partDetails={partOneData} />
        </div>
      ),
    },
    {
      label: "Part 2",
      partType: "part2",
      content: (
        <div>
          <ReportFullTestCardDetails partDetails={partTwoData} />
        </div>
      ),
    },
  ];

  const fetchPartType = () => {
    if (testType === "part1") {
      return [
        {
          label: "Part 1",
          partType: "part1",
          content: <ReportFullTestCardDetails partDetails={partOneData} />,
        },
      ];
    } else if (testType === "part2") {
      return [
        {
          label: "Part 2",
          partType: "part2",
          content: <ReportFullTestCardDetails partDetails={partTwoData} />,
        },
      ];
    } else if (testType === "full") {
      return [
        {
          label: "Part 1",
          partType: "part1",
        },
        { label: "Part 2", partType: "part2" },
      ];
    } else {
      return ["N/A"];
    }
  };

  return (
    <div>
      <div>
        <header className="bg-[#E3E5E9]-500 border-b-2">
          <Tabs
            tabs={testType !== "full" ? fetchPartType() : tabs}
            partType={fetchPartType.toString()}
          />
        </header>
      </div>
    </div>
  );
};

export default DetailedFullTestResultsWriting;
