import RenderCell from "./RenderCell";

const RenderTable = ({ aiFeedback, data }: any) => {
  let tableArray = aiFeedback?.table;
  let partType = aiFeedback?.partType?.toLowerCase().replace(" ", "");
  return (
    <div>
      <table className="border border-[#E3E5E9] rounded-md ">
        <thead className="rounded">
          <tr className="bg-[#FFEAEA] ">
            {Object.keys(tableArray?.[0]).map((item: any, index: any) => (
              <th key={""} className="py-4 px-4 font-avenir">
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableArray.map((row: any, rowIndex: any) => (
            <tr key={""}>
              {Object.values(row).map((cell: any, cellIndex: any) => (
                <td key={""} className="border px-2 py-4 font-avenir">
                  <RenderCell
                    cell={cell}
                    questions={data?.[`${partType}`]?.questions}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RenderTable;
