import api from "./api";

export const speakingResultsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    speakingAiFeedBackResults: builder.mutation({
      query: ({ payload, page }) => ({
        url: `/api/speaking/speakingstudentsdata?page=${page}&limit=${10}`,
        method: "POST",
        body: payload,
      }),
    }),
    fetchSpeakingTestChart: builder.query({
      query: (id) => ({
        url: `/api/speaking/speakingtestchart?studentId=${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useSpeakingAiFeedBackResultsMutation,
  useFetchSpeakingTestChartQuery,
} = speakingResultsApi;
