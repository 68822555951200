import upArrow from "../../../../assets/icons/upArrowAccordian.png";
import downArrow from "../../../../assets/icons/downArrowAccordian.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const AIFeedbackPartHeader = (props: any) => {
  const { type, accordion, percentage, setAccordion, fraction } = props;

  let pathColor;
  let trailColor;
  let textColor;

  if (percentage >= 0 && percentage <= 50) {
    pathColor = " #FF495C";
    trailColor = "#FFC2B6";
    textColor = "#FF495C";
  }
  if (percentage >= 51.11 && percentage <= 77.77) {
    pathColor = "#f59e0b";
    trailColor = "#fefce8";
    textColor = "black";
  }
  if (percentage >= 78.88 && percentage <= 100) {
    pathColor = "#59A51A";
    trailColor = "#DCF8A7";
    textColor = "#59A51A";
  }
  const accordianControl = () => {
    if (type === "Part1") {
      let partA = { ...accordion };
      partA.part1 = !partA.part1;
      setAccordion(partA);
    } else if (type === "Part2") {
      let partB = { ...accordion };
      partB.part2 = !partB.part2;
      setAccordion(partB);
    } else {
      let partC = { ...accordion };
      partC.part3 = !partC.part3;
      setAccordion(partC);
    }
  };

  const iconAccordian = () => {
    if (type === "Part1" && accordion.part1) {
      return upArrow;
    } else if (type === "Part2" && accordion.part2) {
      return upArrow;
    } else if (type === "Part3" && accordion.part3) {
      return upArrow;
    } else {
      return downArrow;
    }
  };

  return (
    <button
      className="h-[80px] w-full flex flex-row justify-between  border-[#E3E5E9]"
      onClick={accordianControl}
    >
      <div className="text text-[#E31837] text-xl font-semibold pl-[16px] pt-[29px]">
        <span className="flex flex-row">
          {type} <img src={iconAccordian()} alt="accordion" />
        </span>
      </div>

      <div className="text-[#4E5664] flex flex-row pt-[11px] pr-[16px]">
        <div className="flex justify-center items-center pr-[8px]">
          Band Score
        </div>{" "}
        <div className="w-[60px] h-[60px]">
          {" "}
          <CircularProgressbar
            value={percentage}
            text={fraction}
            styles={buildStyles({
              rotation: 1,
              strokeLinecap: "round",
              textSize: "16px",
              pathTransitionDuration: 0.5,
              pathColor: pathColor,
              textColor: textColor,
              trailColor: trailColor,
            })}
          />
        </div>
      </div>
    </button>
  );
};

export default AIFeedbackPartHeader;
