import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "./app/Store";
import AddNewTutorForm from "./components/Tutor/AddNewTutor";
import EditTutorForm from "./components/Tutor/EditTutor";
import ArchiveTutor from "./components/Tutor/ArchiveTutor";
import CustomDialog from "./components/Common/CustomDialog";
import UnassignStudent from "./components/Tutor/UnassignStudent";
import AddNewStudentForm from "./components/Students/AddNewStudent";
import Routing from "./components/Routing";
import ImportData from "./components/Students/ImportData";
import { BrowserRouter as Router } from "react-router-dom";
import { SkeletonTheme } from "react-loading-skeleton";

type ModalType =
  | "editTutor"
  | "archiveTutor"
  | "addNewTutor"
  | "unassignStudent"
  | "addNewStudent"
  | "importData";

function App() {
  const modalType = useSelector(
    (state: RootState) => state.root.modal.modalType
  ) as ModalType;

  const getDialogTitle = () => {
    switch (modalType) {
      case "editTutor":
        return "Edit Tutor Details";
      case "archiveTutor":
        return "Archive Tutor";
      case "addNewTutor":
        return "Add New Tutor";
      case "addNewStudent":
        return "Add New Student";
      case "unassignStudent":
        return "Unassign Student(s)";
      case "importData":
        return "Import Data";
      default:
        return "";
    }
  };

  const modalComponents = {
    editTutor: <EditTutorForm />,
    archiveTutor: <ArchiveTutor />,
    addNewTutor: <AddNewTutorForm />,
    addNewStudent: <AddNewStudentForm />,
    unassignStudent: <UnassignStudent />,
    importData: <ImportData />,
  };

  return (
    <>
      <SkeletonTheme baseColor="#E3E5E9" highlightColor="#F8F9FA">
        <Router>
          <Routing />
        </Router>
      </SkeletonTheme>
      <CustomDialog dialogTitle={getDialogTitle()} maxWidth={"max-w-lg"}>
        {modalType && modalComponents[modalType]}
      </CustomDialog>
    </>
  );
}

export default App;
