import { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useAppSelector } from "../../../../app/hooks";
import useProgressBarStyles from "../../../Common/useProgressBarStyles";
import { LinerProgressBar } from "../../../Common/LinearProgressBar";

const ReportCardDetails = (props: any) => {
  const { reportData, key } = props;
  const { score, parameter } = reportData;
  return (
    <div className="flex flex-col w-[16rem] h-1/4 border border-[#E3E5E9] rounded-[8px] mt-[5px] gap-2 mr-2">
      <div className="px-[16px] pt-[16px]">
        <LinerProgressBar score={score} parameter={parameter} />
      </div>
      <ul
        className="pt-[22px] px-[16px] ml-4 "
        style={{ listStyleType: "disc" }}
      >
        {props.reportData.description.map((item: any, index: any) => (
          <li key={key} className="my-2 text-[#737E93]">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const SpeakingTestDetailedReport = () => {
  const [fraction, setFraction] = useState<string>("0");
  let { speakingFullTestDetailedReport } = useAppSelector(
    (state) => state.root.speakingResults
  );
  const { percentage, pathColor, trailColor, textColor } = useProgressBarStyles(
    speakingFullTestDetailedReport
  );
  useEffect(() => {
    if (speakingFullTestDetailedReport) {
      let decimalPart = Number(
        (
          speakingFullTestDetailedReport?.overall_score -
          Math.floor(speakingFullTestDetailedReport?.overall_score)
        ).toFixed(2)
      );
      let value;
      if (decimalPart > 0 && decimalPart <= 0.24) {
        value = Math.floor(speakingFullTestDetailedReport?.overall_score);
      } else if (decimalPart >= 0.25 && decimalPart <= 0.49) {
        value = Math.floor(speakingFullTestDetailedReport?.overall_score) + 0.5;
      } else if (decimalPart > 0.5 && decimalPart <= 0.74) {
        value = Math.floor(speakingFullTestDetailedReport?.overall_score) + 0.5;
      } else if (decimalPart >= 0.75 && decimalPart <= 0.99) {
        value = Math.ceil(speakingFullTestDetailedReport?.overall_score);
      } else {
        value = speakingFullTestDetailedReport?.overall_score;
      }
      value && setFraction(value.toString());
    } else {
      setFraction("0");
    }

    return () => {
      setFraction("0");
    };
  }, [speakingFullTestDetailedReport]);
  return (
    <div>
      <div className="flex flex-row flex-wrap">
        <div className="w-[22.88rem] h-[18.75rem] border border-[#E3E5E9] rounded-[8px] flex justify-center items-center mb-2">
          <div className="w-[180px] h-[180px] ">
            <CircularProgressbar
              value={percentage}
              text={`${fraction ?? "0"}/9`}
              styles={buildStyles({
                rotation: 1,
                strokeLinecap: "round",
                textSize: "20px",
                pathTransitionDuration: 0.5,
                pathColor: pathColor,
                textColor: textColor,
                trailColor: trailColor,
              })}
            />
            <div className="text-[20px] font-Avenir font-medium text-[#3C424C] flex justify-center pt-[20px]">
              Avg Band Score
            </div>
          </div>
        </div>
        <div className="border border-[#E3E5E9] rounded-[8px] w-[44.65rem] h-[18.75rem] lg:ml-[24px] md:ml-[24px] flex flex-col">
          <div className="pl-[31px] pt-[12px] border-b-[1px] border-[#E3E5E9]">
            Score Summary
          </div>
          <div className="flex flex-col mx-[32px] my-[16px] justify-between h-full">
            {speakingFullTestDetailedReport?.detailed_report &&
              speakingFullTestDetailedReport.detailed_report.length > 0 &&
              speakingFullTestDetailedReport.detailed_report.map(
                (item: any, index: any) => {
                  return (
                    <LinerProgressBar
                      key={item.id}
                      score={item.score}
                      parameter={item.parameter}
                    />
                  );
                }
              )}
          </div>
        </div>
      </div>
      <div className="my-[30px]">
        <div className="font-medium text-2xl font-Avenir text-[#292D35] ">
          Detailed Report
        </div>
        <div className="flex flex-row  overflow-x-scroll lg:overflow-x-hidden md:overflow-x-hidden">
          {speakingFullTestDetailedReport.detailed_report &&
            speakingFullTestDetailedReport.detailed_report.length > 0 &&
            speakingFullTestDetailedReport.detailed_report.map(
              (item: any, index: any) => {
                return <ReportCardDetails key={item.id} reportData={item} />;
              }
            )}
        </div>
      </div>
    </div>
  );
};

export default SpeakingTestDetailedReport;
