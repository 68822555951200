import StudentDetails from "../components/Students/StudentDetails";
import StudentsList from "../components/Students/StudentsList";
import AddNewStudenstInBulk from "../components/Students/AddStudentsInBulk";
import { useAppSelector } from "../app/hooks";
import { useEffect } from "react";
import { setItem } from "../utils/storage";

const Students = () => {
  const { studentsBulkClicked } = useAppSelector((state) => state.root.student);
  useEffect(() => {
    setItem("activeMenu", "/students");
  }, []);
  return (
    <>
      {studentsBulkClicked ? (
        <AddNewStudenstInBulk />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 bg-[#F9FBFF] min-h-screen">
          <div className="pt-4 border-r border-r-[#E3E5E9]">
            <StudentsList />
          </div>
          <div className="col-span-1 md:col-span-2">
            <StudentDetails />
          </div>
        </div>
      )}
    </>
  );
};

export default Students;
