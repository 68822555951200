import api from "./api";

export const signupApi = api.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (payload) => ({
        url: "/api/auth/signup",
        method: "POST",
        body: payload,
      }),
    }),
    verifyOtp: builder.mutation({
      query: (payload) => ({
        url: "/api/auth/verifyotp",
        method: "POST",
        body: payload,
      }),
    }),
    verifyUser: builder.mutation({
      query: (userId) => ({
        url: `/api/auth/verifyUser?verificationCode=${userId}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useSignupMutation,useVerifyOtpMutation,useVerifyUserMutation } = signupApi;
