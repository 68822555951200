import CustomButton from "../../Common/CustomButton";
import CustomTextField from "../../Common/CustomTextField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../app/Slices/modalSlice";
import {
  setEmailError,
  setMobileNumberError,
} from "../../../app/Slices/authSlice";
import {
  useAllGroupingStudentsQuery,
  useCreateStudentMutation,
  useValidateUserMutation,
} from "../../../api/institutionTutorApi";
import { useState } from "react";
import {
  setSearchValue,
  setStudentsBulkClicked,
} from "../../../app/Slices/studentSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import "../AddStudentsInBulk/dropDown.css";
import CheckCircleIcon from "../../../assets/icons/checkIcon.svg";
import { useAppSelector } from "../../../app/hooks";
import { capitalizeFirstLetter } from "../../../utils/utils";

const successToastStyle = {
  color: "#292D35",
  fontSize: "12px",
};
const AddNewStudentForm = () => {
  const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;
  const [studentData, setStudentData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setStudentData({ ...studentData, [name]: value });
    if (name === "email" && emailPattern.test(value)) {
      dispatch(setEmailError(""));
    }
  };

  const dispatch = useDispatch();
  const { emailError, mobileNumberError } = useAppSelector(
    (state) => state.root.auth
  );
  const [createStudent, { isLoading }] = useCreateStudentMutation();
  const { refetch } = useAllGroupingStudentsQuery({});
  const [validateUser, { isLoading: validateUserLoading }] =
    useValidateUserMutation();

  const openAddNewStudentDialog = () => {
    dispatch(openModal("importData"));
  };

  const validateInputs = () => {
    if (studentData.email && !emailPattern.test(studentData.email)) {
      dispatch(setEmailError("Please Enter Valid Email Ex:abc@domain.com"));
      return false;
    }
    if (
      studentData.mobileNumber &&
      !isValidPhoneNumber("+" + studentData.mobileNumber)
    ) {
      dispatch(setMobileNumberError("Please Enter Valid Mobile Number"));
      return false;
    }
    return true;
  };

  const clearErrors = () => {
    if (studentData.email && emailPattern.test(studentData.email)) {
      dispatch(setEmailError(""));
    }
    dispatch(setMobileNumberError(""));
  };

  const handleUserExists = (validateUserResponse: any) => {
    if (validateUserResponse?.userexists) {
      toast.error(<div>{validateUserResponse?.message}</div>);
      return true;
    }
    return false;
  };

  const handleSuccess = async () => {
    await refetch();
    toast.success(
      <div style={successToastStyle}>
        {studentData.name} Added Successfully
      </div>,
      {
        autoClose: 5000,
        icon: <img src={CheckCircleIcon} alt="check icon" />,
      }
    );
    dispatch(setSearchValue(""));
  };

  const handleError = (res: any) => {
    if ("error" in res && res.error && "data" in res.error) {
      toast.error(<div style={successToastStyle}>Unable to add student</div>, {
        autoClose: 5000,
      });
    } else {
      dispatch(closeModal());
      handleSuccess();
    }
  };

  const onClickHandler = async () => {
    try {
      if (!validateInputs()) return;
      clearErrors();
      const validateUserResponse = await validateUser({
        name: capitalizeFirstLetter(studentData?.name),
        emailId: studentData.email,
        mobileNumber: "+" + studentData.mobileNumber,
      }).unwrap();

      if (handleUserExists(validateUserResponse)) return;
      const mobileNumber = "+" + studentData.mobileNumber;
      const res = await createStudent([
        { ...studentData, mobileNumber: mobileNumber },
      ]);
      handleError(res);
    } catch (error) {
      console.log(error, "error12");
    }
  };

  const handleChangeMobileNumber = (value: string) => {
    setStudentData({ ...studentData, mobileNumber: value });
  };

  const disableAddStudentBtn = () => {
    if (
      studentData.name === "" ||
      studentData.email === "" ||
      studentData.mobileNumber === "" ||
      isLoading ||
      validateUserLoading
    ) {
      return true;
    } else return false;
  };

  return (
    <>
      <div className="px-6">
        <CustomTextField
          placeholder="Name"
          type="text"
          label="Name"
          onChangeHandler={onChangeHandler}
          value={studentData.name}
          name="name"
          mandatory={true}
        />

        <div className="mt-[13px] mb-[13px]">
          <div className="text-sm mb-1 text-primaryColor">
            {" "}
            Mobile Number
            <span className="text-red-600 text-base font-normal">*</span>
          </div>
          <div className="w-full">
            <PhoneInput
              containerClass="w-full"
              inputClass="border border-gray-300 rounded-2 p-2 focus:outline-none focus:border-black w-[381px] h-[41px]"
              country={"in"}
              value={studentData.mobileNumber}
              onChange={handleChangeMobileNumber}
            />
          </div>
        </div>
        <CustomTextField
          placeholder="Email"
          type="text"
          label="Email"
          onChangeHandler={onChangeHandler}
          value={studentData?.email?.toLowerCase()}
          name="email"
          mandatory={true}
        />
        <p className="text-red-600 text-base font-normal mt-6">{emailError}</p>
        <p className="text-red-600 text-base font-normal mt-6">
          {mobileNumberError}
        </p>
        <div className="flex flex-col">
          <button
            onClick={() => {
              dispatch(setStudentsBulkClicked(true));
              dispatch(closeModal());
            }}
            className="mt-4 text-secondaryColor text-sm underline"
          >
            Click here for bulk addition
          </button>
          <button
            onClick={() => {
              openAddNewStudentDialog();
            }}
            className="mt-4 text-secondaryColor text-sm underline"
          >
            Click here for import data
          </button>
        </div>
      </div>
      <div className="mt-4 flex justify-center border-t border-t-[#E3E5E9] py-4">
        <div className="px-6 w-full">
          <CustomButton
            isLoading={isLoading || validateUserLoading}
            name="ADD STUDENT"
            onClickHandler={onClickHandler}
            width="w-full"
            disabled={disableAddStudentBtn()}
          />
        </div>
      </div>
    </>
  );
};

export default AddNewStudentForm;
