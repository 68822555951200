import { Menu } from "@headlessui/react";
import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";

export type SortingType = {
  sortTypeNumber: number;
  handleAscendingSorting: () => void | {};
  handleDescendingSorting: () => void | {};
};

const Sorting = ({
  sortTypeNumber,
  handleAscendingSorting,
  handleDescendingSorting,
}: SortingType) => {
  return (
    <div className="flex flex-col mt-3">
      <Menu.Item>
        <button
          style={{ color: sortTypeNumber === 1 ? "red" : "#737E93" }}
          onClick={handleAscendingSorting}
          className="flex items-center"
        >
          <FaArrowUpLong className="mr-3" />
          <p>Ascending</p>
        </button>
      </Menu.Item>
      <Menu.Item>
        <button
          style={{ color: sortTypeNumber === 2 ? "red" : "#737E93" }}
          onClick={handleDescendingSorting}
          className="flex items-center mt-2"
        >
          <FaArrowDownLong className="mr-3" />
          <p>Descending</p>
        </button>
      </Menu.Item>
    </div>
  );
};

export default Sorting;
