const CustomRadio = ({
  checked,
  label,
  value,
  onChangeHandler,
  name,
}: {
  checked?: boolean;
  label: string;
  value: string;
  onChangeHandler: any;
  name: string;
}) => {
  return (
    <div className="flex items-center mb-4 hover:bg-[#FFEAEA] p-2">
      <input
        id="country-option-1"
        type="radio"
        name={name}
        value={value}
        className="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300"
        aria-labelledby="country-option-1"
        aria-describedby="country-option-1"
        checked={checked}
        onChange={(e)=>onChangeHandler(e)}
      />
      <label className={`text-sm font-medium ${checked ? "text-black" : "text-[#737E93]"} ml-2 block `}>
        {label}
      </label>
    </div>
  );
};

export default CustomRadio;
