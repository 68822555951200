import api from "./api";

export const profileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    userProfile: builder.query({
      query: () => ({
        url: "/api/user/userprofile",
        method: "GET",
      }),
    }),
    orgApiTutor: builder.query({
      query: (orgId) => ({
        url: `api/tutoradmin/gettutoradmin?adminId=${orgId}`,
        method: "GET",
      }),
    }),
    updateB2B: builder.mutation({
      query: ({ adminId, payload }) => ({
        url: `api/tutoradmin/updatetutoradmin?adminId=${adminId}`,
        method: "PUT",
        body: payload,
      }),
    }),
    updateB2C: builder.mutation({
      query: ({ tutorId, payload }) => ({
        url: `api/tutor/updatetutor?tutorId=${tutorId}`,
        method: "PUT",
        body: payload,
      }),
    }),
  }),
});
export const {
  useUserProfileQuery,
  useLazyOrgApiTutorQuery,
  useUpdateB2BMutation,
  useUpdateB2CMutation,
  useLazyUserProfileQuery,
} = profileApi;
