import api from "./api";

export const readingResultsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readingAiFeedBackResults: builder.mutation({
      query: ({ payload, page }) => ({
        url: `/api/reading/readingstudentsdata?page=${page}&limit=${10}`,
        method: "POST",
        body: payload,
      }),
    }),
    fetchReadingTestChart: builder.query({
      query: (id) => ({
        url: `/api/reading/readingtestchart?studentId=${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useReadingAiFeedBackResultsMutation,
  useFetchReadingTestChartQuery,
} = readingResultsApi;
