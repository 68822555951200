import CustomSearch from "../../Common/CustomSearch";
import assign from "../../../assets/icons/assign.png";
import reAssign from "../../../assets/icons/reassign.png";
import user from "../../../assets/images/user.svg";
import { useEffect, useMemo, useState } from "react";
import "./table.css";
import CustomButton from "../../Common/CustomButton";
import { toast } from "react-toastify";
import CustomSideOverlay from "../../Common/CustomSideOverlay";
import { useDispatch } from "react-redux";
import { openModal } from "../../../app/Slices/modalSlice";
import {
  setAssignStudents,
  setAssignedAndUnassignedStudents,
  setReassign,
  setSelectedRows,
} from "../../../app/Slices/tutorSlice";
import {
  useAssignStudentsMutation,
  useLazyAllGroupingStudentsQuery,
} from "../../../api/institutionTutorApi";
import { useAppSelector } from "../../../app/hooks";
import { useWritingAiFeedBackResultsMutation } from "../../../api/writingResultsApi";
import { useSpeakingAiFeedBackResultsMutation } from "../../../api/speakingResultsApi";
import {
  setLastPageSpeaking,
  setSpeakingCurrentPage,
  setSpeakingResponseData,
} from "../../../app/Slices/speakingResultsSlice";
import {
  setLastPageWriting,
  setWritingCurrentPage,
  setWritingResultData,
} from "../../../app/Slices/writingResultsSlice";
import {
  setDashboards,
  setSearchValue,
  setSelectedStudent,
  setSelectedStudentDetails,
  setSelectedStudentMobileNumber,
  setTabs,
  setTotalOverAllBandScoreTutor,
  setTotalTestsCompleted,
} from "../../../app/Slices/studentSlice";
import CircularProgressBar from "../../Common/CircularProgressBar";
import StudentProfile from "../../Students/StudentProfile";
import Table from "../../Common/CustomTable";
import ReAssignTutor from "../ReAssignTutor";
import { useReadingAiFeedBackResultsMutation } from "../../../api/readingResultsApi";
import {
  setReadingCurrentPage,
  setReadingLastPage,
  setReadingResultsData,
} from "../../../app/Slices/readingResultsSlice";
import { setSearchValueStudent } from "../../../app/Slices/filtersSlice";
import { setItem } from "../../../utils/storage";
import { useListeningAiFeedBackResultsMutation } from "../../../api/listeningResultsApi";
import {
  setListeningCurrentPage,
  setListeningLastPage,
  setListeningResultsData,
} from "../../../app/Slices/listeningResultsSlice";
import StudentFilters from "../../Common/FiltersDropDown/StudentsFilters";
import useFilters from "../../CustomHooks/useFilters";
import { dashboardDecoding, fetchStudents } from "../../../utils/utils";

const Students = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [studentClicked, setStudentClicked] = useState(false);
  const { selectedTutorDetails, selectedTutorId, selectedRows } =
    useAppSelector((state) => state.root.tutor);
  const {
    totalOverAllBandScoreTutor,
    testsCompletedCount,
    searchValue,
    selectedStudentDetails,
    studentsListTutor,
  } = useAppSelector((state) => state.root.student);
  const [writingAiFeedBackResults, { data: writingData }] =
    useWritingAiFeedBackResultsMutation();
  const [
    speakingAiFeedBackResults,
    { data: speakingData, isLoading: speakingLoader },
  ] = useSpeakingAiFeedBackResultsMutation();

  const [readingAiFeedBackResults, { data: readingData }] =
    useReadingAiFeedBackResultsMutation();

  const [listeningAiFeedBackResults, { data: listeningData }] =
    useListeningAiFeedBackResultsMutation();

  const dispatch = useDispatch();
  const [allGroupingStudents] = useLazyAllGroupingStudentsQuery();

  const {
    clearFilters,
    filters,
    handleAscendingSorting,
    handleDescendingSorting,
    onChangeRadioHandler,
  } = useFilters(allGroupingStudents);

  const fetchStudentRows = useMemo(() => {
    return fetchStudents(
      searchValue,
      studentsListTutor?.allstudents,
      selectedTutorId,
      "assigned"
    );
  }, [searchValue, studentsListTutor?.allstudents, selectedTutorId]);

  useEffect(() => {
    dispatch(
      setAssignedAndUnassignedStudents(
        studentsListTutor?.allstudents?.filter((item: any) => {
          if (item.tutorId === selectedTutorId) {
            return item;
          }
        })
      )
    );
  }, [dispatch, selectedTutorId, studentsListTutor]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    dispatch(setReassign(true));
    dispatch(setSearchValue(""));
    dispatch(setSearchValueStudent(""));
  };
  const handleCloseSidebar = () => {
    setStudentClicked(false);
  };
  useEffect(() => {
    dispatch(setAssignStudents(true));
    dispatch(setReassign(false));
    dispatch(setSearchValue(""));
  }, [dispatch]);

  const renderCell = (row: any, value: any) => {
    return <div>{value}</div>;
  };

  const bandScore = (row: any) => {
    return (
      <div style={{ width: 40, height: 40 }}>
        {row.overAllBandScore ? (
          <CircularProgressBar score={[row.overAllBandScore]} />
        ) : (
          <CircularProgressBar score={[0]} />
        )}
      </div>
    );
  };

  const studentColumns: any = [
    {
      field: "name",
      name: "Students",
    },
    {
      field: "testsCompletedCount",
      name: "Tests Completed",
      selector: (row: any) => renderCell(row, row?.testsCompletedCount ?? "0"),
    },
    {
      field: "overAllBandScore",
      name: "Band Score",
      selector: (row: any) => bandScore(row),
    },
  ];

  useEffect(() => {
    if (speakingData) {
      dispatch(setSpeakingResponseData(speakingData?.data));
      dispatch(setLastPageSpeaking(speakingData?.lastPageNumber));
    }
    if (writingData) {
      dispatch(setWritingResultData(writingData?.data));
      dispatch(setLastPageWriting(writingData?.lastPageNumber));
    }
    if (readingData) {
      dispatch(setReadingResultsData(readingData?.data));
      dispatch(setReadingLastPage(readingData?.lastPageNumber));
    }
    if (listeningData) {
      dispatch(setListeningResultsData(listeningData?.data));
      dispatch(setListeningLastPage(listeningData?.lastPageNumber));
    }
  }, [speakingData, writingData, readingData, listeningData, dispatch]);

  const handleRowClicked = async (row: any) => {
    let dashboards: any;
    const clickedRowId = row._id;
    dispatch(setTotalTestsCompleted(row.testsCompletedCount));
    dispatch(setTotalOverAllBandScoreTutor(row.overAllBandScore));
    if (clickedRowId !== null) {
      dashboards = row.dashboard;
      dispatch(setSelectedStudentDetails(row));
      dispatch(setSelectedStudentMobileNumber(row.mobileNumber));
      if (dashboards.includes(4002)) {
        await writingAiFeedBackResults({
          payload: [row?.mobileNumber],
          page: 1,
        });
      }
      if (dashboards.includes(4001)) {
        await speakingAiFeedBackResults({
          payload: [row?.mobileNumber],
          page: 1,
        });
      }
      if (dashboards.includes(4003)) {
        readingAiFeedBackResults({ payload: [row?.mobileNumber], page: 1 });
      }
      if (dashboards.includes(4004)) {
        listeningAiFeedBackResults({ payload: [row?.mobileNumber], page: 1 });
      }
      setStudentClicked(true);

      dispatch(setDashboards(dashboards));
      if (toggleSidebar) {
        toggleSidebar();
      }
    }
    let dashboardsTab = dashboardDecoding(dashboards);
    dispatch(setTabs(dashboardsTab));
  };

  const [assignStudents] = useAssignStudentsMutation();

  const reAssignBulkStudents = async () => {
    try {
      const res = await assignStudents({
        id: selectedRows.tutorId,
        payload: selectedRows.studentIds,
      });

      if ("error" in res && res.error && "data" in res.error) {
        toast.error("Unable to reassign student ");
      } else {
        dispatch(
          setSelectedRows({
            studentsIds: [],
            tutorId: "",
          })
        );
        allGroupingStudents({});
        toggleSidebar();
        toast.success("Students Reassigned Successfully");
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const resetCurrentPage = () => {
    dispatch(setSpeakingCurrentPage(1));
    dispatch(setReadingCurrentPage(1));
    dispatch(setListeningCurrentPage(1));
    dispatch(setWritingCurrentPage(1));
  };

  const getSelectedRow = (id: string, data: any, row: any) => {
    handleRowClicked(row);
    setItem("mobileNo", row?.mobileNumber);
    dispatch(setSelectedStudentMobileNumber(row?.mobileNumber));
    setItem("selectedStudentName", row?.name);
    dispatch(setSelectedStudent(row?._id));
    dispatch(setSelectedStudentDetails(row));
    dispatch(setSelectedRows({ studentIds: [row?._id] }));
    setItem("selectedStudentName", row?.name);
    resetCurrentPage();
  };

  const getSelectedData = (data: any) => {
    resetCurrentPage();
    const studentIds = data?.map((item: any) => item._id);
    const studentNames = data?.map((item: any) => item.name);
    dispatch(setSelectedRows({ studentIds, tutorId: "", studentNames }));
  };

  return (
    <>
      <CustomSideOverlay
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        title={studentClicked ? "Student Profile" : "Reassign Student(s)"}
        width={studentClicked ? "w-[40%]" : ""}
        handleCloseSidebar={handleCloseSidebar}
      >
        {studentClicked && !speakingLoader ? (
          <>
            <div className="flex justify-between items-center px-6 py-5">
              {/* Student Profile Section */}
              <div className="flex">
                <img
                  style={{ width: "88px", height: "88px" }}
                  src={
                    selectedStudentDetails?.profilePhoto
                      ? selectedStudentDetails.profilePhoto
                      : user
                  }
                  alt="tutor_image"
                />
                <div className="w-full">
                  <div>
                    <p className="px-4 text-2xl text-primaryColor">
                      {selectedStudentDetails.name}
                    </p>
                    <p className="px-4 text-sm text-grey">
                      Assigned to{" "}
                      {selectedTutorDetails ? selectedTutorDetails.name : "N/A"}
                    </p>
                    <CustomButton
                      color="text-secondaryColor"
                      width=""
                      bg="bg-[transparent]"
                      name="Reassign"
                      onClickHandler={() => {
                        setIsSidebarOpen(true);
                        setStudentClicked(false);
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* Tests Attempted Section */}
              <div className="flex justify-center items-center flex-col">
                <div className="flex justify-center border text-grey border-[#E3E5E9] rounded-xl p-3 w-1/2 text-center">
                  <p className="text-base">{testsCompletedCount ?? "N/A"}</p>
                </div>
                <p className="text-center mt-4 text-xs text-grey">
                  Tests Completed
                </p>
              </div>
              {/* Band Score Overall Section */}
              <div className="flex flex-col justify-center items-center">
                <div className="w-12">
                  <CircularProgressBar score={[totalOverAllBandScoreTutor]} />{" "}
                </div>
                <p className="mt-4 text-center text-xs text-grey">
                  Band Score Overall
                </p>
              </div>
            </div>
            <StudentProfile />
            <div className="border-t border-t-[#E3E5E9] 2xl:w-[35%] xl:w-[40%] lg-[45%] md:w-[50%] sm:[50%] w-[80%] fixed bottom-0 flex flex-col bg-white">
              <div className="px-10 py-4">
                <CustomButton
                  width="w-full"
                  name="REASSIGN STUDENT(s)"
                  onClickHandler={() => {
                    setIsSidebarOpen(true);
                    setStudentClicked(false);
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <ReAssignTutor reAssignBulkStudents={reAssignBulkStudents} />
        )}
      </CustomSideOverlay>

      {/* Filter and Action Buttons Section */}
      <div className="px-4 flex justify-between items-center">
        <CustomSearch
          value={searchValue}
          handleSearchChange={(e) => {
            dispatch(setSearchValue(e.target.value));
          }}
        />
        <div className="flex items-center px-6 w-full justify-end">
          <StudentFilters
            handleDescendingSorting={handleDescendingSorting}
            handleAscendingSorting={handleAscendingSorting}
            onChangeRadioHandler={onChangeRadioHandler}
            filters={filters}
            clearFilters={clearFilters}
          />
          <button
            disabled={selectedRows?.studentIds?.length === 0}
            onClick={() => dispatch(openModal("unassignStudent"))}
          >
            <img
              style={{ width: "20px", height: "22px" }}
              className="mr-5 relative"
              src={assign}
              alt="Unassign Student"
              title={
                selectedRows?.studentIds?.length === 0
                  ? "Please select atleast one student to perform unassign action"
                  : "Unassign Student"
              }
            />
          </button>
          <button
            disabled={selectedRows?.studentIds?.length === 0}
            onClick={toggleSidebar}
          >
            <img
              style={{ width: "20px", height: "18px" }}
              src={reAssign}
              alt="icon"
              title={
                selectedRows?.studentIds?.length === 0
                  ? "Please select atleast one student to perform reassign action"
                  : "Reassign Student"
              }
            />
          </button>
        </div>
      </div>
      <Table
        getSelectedRow={getSelectedRow}
        getSelectedData={getSelectedData}
        columns={studentColumns}
        data={fetchStudentRows}
      />
    </>
  );
};

export default Students;
