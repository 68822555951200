import PhoneInput from "react-phone-input-2";
import CustomTextField from "../../Common/CustomTextField";
import { ReactNode } from "react";
import { CiCamera } from "react-icons/ci";
import { getItem } from "../../../utils/storage";
import { useAppSelector } from "../../../app/hooks";

type ProfileProps = {
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  profilePhoto: any;
  selectedFile: File | null;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  mobileNumber: string;
  email: string;
  handleChangeMobileNumber: (value: string) => void;
  emailError: string | null;
  mobileNumberError: string;
  children: ReactNode;
  orgName?: string;
  data?: any;
};

const CommonEdit = ({
  handleFileChange,
  profilePhoto,
  selectedFile,
  onChangeHandler,
  name,
  mobileNumber,
  email,
  handleChangeMobileNumber,
  emailError,
  mobileNumberError,
  children,
  orgName,
  data,
}: ProfileProps) => {
  const { isOrg } = useAppSelector((state) => state.root.auth);
  const roles = getItem("roles");
  const defaultOrgName =
    isOrg && roles?.includes("TUTOR") ? data?.name : orgName ?? "";

  return (
    <div>
      <div className="px-6">
        <div className="flex flex-col mt-5 mb-1">
          <p>Profile Photo :</p>
          <label htmlFor="profilePhoto" className="custom-file-input">
            <input
              type="file"
              className="hidden"
              onChange={handleFileChange}
              accept="image/png, image/jpg, image/jpeg"
              id="profilePhoto"
            />
            <div className="flex items-center">
              <div className="w-20 mr-3 h-20 bg-[#E3E5E9] rounded-full flex items-center justify-center cursor-pointer">
                {profilePhoto ? (
                  <img
                    className="w-16 h-16 rounded-full"
                    src={profilePhoto}
                    alt="profile_photo"
                  />
                ) : (
                  <CiCamera className="text-3xl" />
                )}
              </div>
              <span>{selectedFile ? selectedFile.name : ""}</span>
            </div>
            <p className="mt-3 text-xs text-gray-400">
              Note: Only jpg/jpeg and png files are allowed!
            </p>
          </label>
        </div>
        <CustomTextField
          placeholder="Name"
          type="text"
          label="Name :"
          onChangeHandler={onChangeHandler}
          value={name ?? ""}
          name="name"
          mandatory={true}
        />
        <div className="mt-[13px] mb-[13px]">
          <div className="text-sm mb-1 text-primaryColor">
            {" "}
            Mobile Number :
            <span className="text-red-600 text-base font-normal">*</span>
          </div>
          <div className="w-full">
            <PhoneInput
              containerClass="w-full"
              inputClass="border border-gray-300 rounded-2 p-2 focus:outline-none focus:border-black w-[381px] h-[41px]"
              country={"in"}
              value={mobileNumber ?? ""}
              onChange={handleChangeMobileNumber}
            />
          </div>
        </div>
        <CustomTextField
          placeholder="Email"
          type="text"
          label="Email :"
          onChangeHandler={onChangeHandler}
          value={email ?? ""}
          name="email"
          mandatory={true}
        />
        {orgName && data && (
          <CustomTextField
            placeholder=""
            type="text"
            label="Organization Name :"
            onChangeHandler={onChangeHandler}
            value={defaultOrgName}
            name="orgName"
            mandatory={true}
            disabled={isOrg && roles.includes("TUTOR")}
            readonly={isOrg && roles.includes("TUTOR")}
          />
        )}
      </div>
      <p className="text-red-600 text-base font-normal mt-6 ml-8">
        {emailError}
      </p>
      <p className="text-red-600 text-base font-normal mt-6 ml-8">
        {mobileNumberError}
      </p>
      <div className="my-4 flex justify-center">
        <div className="px-6 w-full">{children}</div>
      </div>
    </div>
  );
};

export default CommonEdit;
