import { createApi } from "@reduxjs/toolkit/query/react";
import {serviceUrl} from '../utils/apiConfig';
import { getItem } from "../utils/storage";
import customBaseQuery from "./customBaseQuery";

const api = createApi({
  baseQuery: customBaseQuery({
    baseUrl: serviceUrl,
    prepareHeaders: (headers, { getState, endpoint }) => {
      headers.set("Accept", "application/json");
      headers.set("Content-Type", "application/json");
      const token = getItem('token');
      if(token){
        headers.set("Authorization" , `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['AssignStudentsToTutor', 'GetAlltutors','AllGroupingStudents','10DayActivityGraph'],
  endpoints: () => ({}),
});

export default api;




