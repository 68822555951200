export function formatDateToCustomFormat(date: Date): string {
  const day = date.getDate().toString().padStart(2, "0");
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  return `${day} ${month}`;
}

export function calculateOverallBadScore(array: any) {
  const totalData = array;
  const totalScore = totalData?.reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue,
    0
  );
  const totalScoreLength = totalData?.length;
  const averageScore = totalScore / totalScoreLength;
  return calculateFraction(averageScore);
}

export const calculateFraction = (averageScore: any) => {
  if (averageScore) {
    let decimalPart = Number(
      (averageScore - Math.floor(averageScore)).toFixed(2)
    );
    let value;
    if (decimalPart > 0 && decimalPart <= 0.24) {
      value = Math.floor(averageScore);
    } else if (decimalPart >= 0.25 && decimalPart <= 0.49) {
      value = Math.floor(averageScore) + 0.5;
    } else if (decimalPart > 0.5 && decimalPart <= 0.74) {
      value = Math.floor(averageScore) + 0.5;
    } else if (decimalPart >= 0.75 && decimalPart <= 0.99) {
      value = Math.ceil(averageScore);
    } else {
      value = averageScore;
    }
    return value;
  } else {
    return 0;
  }
};
