import "react-circular-progressbar/dist/styles.css";
import Tab from "../components/Common/Tab";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../app/hooks";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { setTestId } from "../app/Slices/tutorSlice";
import { getItem } from "../utils/storage";
import { setTestType } from "../app/Slices/speakingResultsSlice";
import { setSelectedStudentMobileNumber } from "../app/Slices/studentSlice";
import axios from "axios";
import { useReadingAiFeedBackResultsMutation } from "../api/readingResultsApi";
import ReadingAIFeedback from "../components/Students/AIFeedBack/Reading/ReadingAIFeedback";
import {
  setReadingAiFeedback,
  setReadingFullTestDetailedReport,
  setReadingResultsData,
  setReadingTestScorePartOne,
  setReadingTestScorePartThree,
  setReadingTestScorePartTwo,
} from "../app/Slices/readingResultsSlice";
import ReadingTestResults from "../components/Students/AIFeedBack/Reading/ReadingTestResults";
import { setLoader } from "../app/Slices/loaderSlice";
import { ielts_url } from "../utils/apiConfig";
import SubmissionInfo from "../components/Students/AIFeedBack/SubmissionInfoHeader";
import ConditionalRenderer from "../components/Students/AIFeedBack/ConditionalResultsRender";

const ReadingTestResultsMain = () => {
  const testType = getItem("testType");
  const { id } = useParams();
  const dispatch = useDispatch();
  const { readingResultsData } = useAppSelector(
    (state) => state.root.readingResults
  );
  const [readingError, setReadingError] = useState(false);
  const { dashboards, loaderStudent } = useAppSelector(
    (state) => state.root.student
  );
  const { isLoading } = useAppSelector((state) => state.root.loader);
  const mobile = getItem("mobileNo");
  const test = getItem("testType");
  const mobileNumbers = getItem("mobileNumbers");

  const [readingAiFeedBackResults, { data: readingData }] =
    useReadingAiFeedBackResultsMutation();

  const userId = getItem("userId");

  const fetchReadingTestResults = async () => {
    dispatch(setLoader(true));
    try {
      const res = await axios.get(
        `${ielts_url}/api/get-reading-test-score-tutor?test_id=${id}&user_id=${userId}`
      );
      if (res.status === 200) {
        setReadingError(false);
        dispatch(setLoader(false));
        dispatch(setReadingTestScorePartOne(res.data["part1"]));
        dispatch(setReadingTestScorePartTwo(res.data["part2"]));
        dispatch(setReadingTestScorePartThree(res.data["part3"]));
        dispatch(setReadingFullTestDetailedReport(res.data));
      }
    } catch (error) {
      setReadingError(true);
      dispatch(setLoader(false));
      console.log(error, "error fetching reading test results");
    }
  };

  const getReadingTestQuestionAnalysis = async () => {
    try {
      dispatch(setLoader(true));
      const res = await axios.get(
        `${ielts_url}/api/get-reading-question-analysis-tutor?test_id=${id}&user_id=${userId}`
      );
      if (res.status === 200) {
        dispatch(setLoader(false));
        dispatch(setReadingAiFeedback(res.data));
      }
    } catch (error) {
      dispatch(setLoader(false));
      console.log(error, "error fetching reading test results");
    }
  };
  useEffect(() => {
    if (id && userId) {
      getReadingTestQuestionAnalysis();
      fetchReadingTestResults();
    }
  }, [id, userId]);

  useEffect(() => {
    if (Object.keys(readingResultsData).length === 0) {
      if (mobileNumbers && testType !== "full") {
        readingAiFeedBackResults({
          payload: JSON.parse(mobileNumbers),
          page: 1,
        });
      }
      if (mobile && testType !== "full") {
        readingAiFeedBackResults({ payload: [mobile], page: 1 });
      }
    }
  }, []);

  useEffect(() => {
    if (readingData) {
      dispatch(setReadingResultsData(readingData?.data));
    }
  }, [dispatch, readingData]);

  useEffect(() => {
    if (id) {
      dispatch(setTestId(id));
    }
    if (mobile) {
      dispatch(setSelectedStudentMobileNumber(mobile));
    }
    if (test) {
      dispatch(setTestType(test));
    }
  }, [dispatch, id, mobile, test]);

  return (
    <ConditionalRenderer
      isLoading={isLoading}
      loaderStudent={loaderStudent}
      dashboards={dashboards}
      isError={readingError}
    >
      <>
        <SubmissionInfo />
        <div className="px-10 ">
          <Tab
            classes={""}
            activeClass={"text-[red] border-b-[3px] border-secondaryColor"}
            getActiveTab={(activeTabId) => {}}
            tabs={[
              { id: "result", value: "Result" },
              { id: "aiFeedback", value: "AI Feedback" },
            ]}
          >
            <Tab.Body id={"result"}>
              <div className="mt-5">
                <ReadingTestResults />
              </div>
            </Tab.Body>
            <Tab.Body id="aiFeedback">
              <div className="flex justify-between items-center">
                <ReadingAIFeedback />
              </div>
            </Tab.Body>
          </Tab>
        </div>
      </>
    </ConditionalRenderer>
  );
};

export default ReadingTestResultsMain;
