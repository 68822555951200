import clsx from "clsx";
import { twMerge } from "tailwind-merge";

import axios, { AxiosError } from "axios";
import { cloudServiceUrl } from "./apiConfig";
import { getItem } from "./storage";
import { toast } from "react-toastify";
import { jwtDecode, JwtPayload } from "jwt-decode";

const headers1 = {
  username: "ictsystems",
  password: "123456789",
  "x-api-key": "987654321",
  applicationurl: "localhost:3000",
};

export const setItemForLocal = (key: string, value: any) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.error("Error setting item in localStorage", error);
  }
};

export const getItemFromLocal = (key: string) => {
  try {
    const item = localStorage.getItem(key);
    return item;
  } catch (error) {
    console.error("Error getting item from localStorage", error);
    return null;
  }
};

export const loginToCloudService = async () => {
  try {
    // const url = `${cloudServiceUrl}auth/login`;
    const url = `${cloudServiceUrl}/auth/get/token`;
    const payload = {
      userName: "aWN0c3lzdGVtc0BtYWlsaW5hdG9yLmNvbQ==",
      password: "MTIzNDU2Nzg5",
    };
    const response = await axios.post(url, payload);
    if (response.status === 200) {
      setItemForLocal("cloudToken", response.data?.data);
      return response.data?.data;
    }
  } catch (err) {
    toast.error("cloud Service login failed");
    console.error(err);
  }
};
export const cloudTokenExpiry = () => {
  let cloudToken = getItemFromLocal("cloudToken");
  let decodedToken: JwtPayload | null = null;

  if (cloudToken) {
    decodedToken = jwtDecode<JwtPayload>(cloudToken);
  } else {
    return true;
  }

  const currentTime = Math.floor(Date.now() / 1000);
  const tokenExpired =
    decodedToken?.exp !== undefined ? decodedToken.exp < currentTime : true;
  return tokenExpired;
};

export const upload_file_to_s3 = async (file: any) => {
  let tokenExpired = cloudTokenExpiry();
  let cloudServiceToken;
  if (tokenExpired) {
    cloudServiceToken = await loginToCloudService();
  }

  try {
    const url = `${cloudServiceUrl}/s3/upload`;
    const formData = new FormData();
    formData.append("file", file);
    const res = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${
          cloudServiceToken || getItemFromLocal("cloudToken")
        }`,
        ...headers1,
        Accept: "*/*",
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(res, "inside upload_to_s3");
    if (res.status === 200) {
      handleView(res.data);
      return res.data;
      //set the response object to any state
    }
  } catch (err) {
    if (err instanceof AxiosError) {
      const statusCode = err.response?.status;
      if (statusCode === 413) {
        toast.error("File size exceeds more than 10MB");
      } else {
        toast.error("File Upload Unsuccessful");
      }
    }
    //if any error is handle here
  }
};

export const handleView = async (fileData: any) => {
  console.log(fileData, "fileData");
  const s3Data = fileData?.data;
  const s3Url = await getFile(s3Data?.fileName);

  if (!s3Url) {
    return;
  }

  try {
    const response = await fetch(s3Url);

    const blob = await response.blob();
    const fileURL = window.URL.createObjectURL(blob);

    window.open(fileURL, "_blank");
  } catch (error) {
    toast.error("Failed to open the file");
    console.error("Error viewing file:", error);
  }
};

export const getFile = async (file_name: any) => {
   let tokenExpired = cloudTokenExpiry();
   let cloudServiceToken;
   if (tokenExpired) {
     cloudServiceToken = await loginToCloudService();
   }
  try {
    const url = `${cloudServiceUrl}/s3/generate/presigned/url`;
    let payload = {
      objectKey: file_name,
    };
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${
          cloudServiceToken || getItemFromLocal("cloudToken")
        }`,
      },
    });
    console.log(response, "inside FileUpload");
    if (response.status === 200) {
      return response?.data?.data;
    }
    return "";
  } catch (error) {
    console.error("Error fetching data:", error);
    return "";
  }
};

export const safe_get = (
  map_object: any,
  key: string,
  defaultValue: any,
  separator: string = "|"
) => {
  try {
    let current = map_object;
    const parts = key.split(separator);
    for (const part of parts) {
      if (Object.keys(current).includes(part)) {
        current = current[part];
      } else {
        return defaultValue;
      }
    }
    return current;
  } catch (e) {}

  return defaultValue;
};

export const tutorColumn: any = [
  {
    field: "name",
    name: "Tutors",
    selector: (row: any) => row.name,
    sortable: false,
    style: {
      fontSize: "18px",
      color: "#292D35",
    },
  },
  {
    field: "noOfStudentsAssigned",
    name: "Students",
    selector: (row: any) => row.noOfStudentsAssigned,
    sortable: false,
    style: {
      fontSize: "18px",
      color: "#292D35",
    },
  },
  {
    field: "noOfTestsGraded",
    name: "Graded Tests",
    selector: (row: any) => row.noOfTestsGraded,
    sortable: false,
    style: {
      fontSize: "18px",
      color: "#292D35",
    },
  },
  {
    field: "noOfTestsPending",
    name: "Pending Tests",
    selector: (row: any) => row.noOfTestsPending,
    sortable: false,
    style: {
      fontSize: "18px",
      color: "#292D35",
    },
  },
];

export const dashboardDecoding = (dashboards: any) => {
  return dashboards.map((item: any) => {
    if (item === 4001) {
      return { id: "speaking", value: "Speaking" };
    }
    if (item === 4002) {
      return { id: "writing", value: "Writing" };
    }
    if (item === 4003) {
      return { id: "reading", value: "Reading" };
    }
    if (item === 4004) {
      return { id: "listening", value: "Listening" };
    }
    return { id: "-", value: "" };
  });
};

export const fetchStudents = (
  searchValue: string,
  studentsListTutor: any,
  selectedTutorId: string | number,
  key?: "assigned"
) => {
  if (searchValue) {
    return studentsListTutor?.filter(
      (item: any) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase()) &&
        item.tutorId === selectedTutorId
    );
  } else if (key) {
    return studentsListTutor?.filter(
      (item: any) => item.tutorId === selectedTutorId
    );
  } else {
    return studentsListTutor?.filter(
      (item: any) => item.tutorId !== selectedTutorId
    );
  }
};

export function getClassName(item: any, aiFeedback: any, part: string) {
  const baseClass = "border cursor-pointer px-3 py-1 rounded-md";
  let additionalClass = "";

  const isFeedbackMatch =
    aiFeedback?.questionId === item.qId &&
    aiFeedback?.partType === part.toLowerCase();

  if (item.isCorrect) {
    additionalClass = isFeedbackMatch
      ? "bg-[#59A51A] text-[#FFFFFF]"
      : "border-[#59A51A]";
  } else if (!item.isAttempted) {
    additionalClass = isFeedbackMatch
      ? "text-[#FFFFFF] bg-[#737E93] border-[#737E93]"
      : "border-[#737E93]";
  } else {
    additionalClass = isFeedbackMatch
      ? "bg-[#DB3555] text-[#FFFFFF] border-[#DB3555]"
      : "border-[#DB3555]";
  }
  return twMerge(clsx(baseClass, additionalClass));
}

export const capitalizeFirstLetter = (name: string) => {
  if (!name || name.length === 0) {
    return "";
  }
  if (!/^[A-Za-z]/.test(name)) {
    return name;
  }
  return name.charAt(0).toUpperCase() + name.slice(1);
};
